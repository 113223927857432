<template>
  <div>
    <div class="modal fade" id="modal__add_benhan">
      <div v-loading="loading" class="modal-dialog modal-lg shadow" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ update ? "Chỉnh sửa Bệnh án" : "Tạo mới Bệnh án" }}</h5>
          </div>
          <div class="modal-body txt-grey-900">
            <div class="row flex justify-end">
              <div class="col-md-6 flex items-end justify-end" v-if="!person && !update">
                <ButtonHoDo
                  title="Thêm Bệnh nhân"
                  @click="showModalAddPerson(true)"
                  class-name="bg-blue-700 text-white rounded-lg h-10 px-3 py-2 mx-3"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="fs-14 fw-500">Bệnh nhân</div>
                <el-select
                  class="w-full"
                  v-model="personName"
                  filterable
                  placeholder="Chọn bệnh nhân"
                  @change="setPersonID"
                  remote
                  :remote-method="getPersonsWS"
                  :loading="loading"
                  @focus="getPersonsWS()"
                >
                  <el-option
                    v-for="item in listPerson"
                    :key="item.id"
                    :label="handleGetPatientLabel(item)"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-md-6">
                <label class="fs-14 fw-500">Mã điều trị</label>
                <input
                  v-model="form.ref_id"
                  type="text"
                  class="form--input fw-500 h-10"
                  placeholder="Nhập mã điều trị"
                />
              </div>
            </div>
            <div class="mt-6 row">
              <div class="col-md-6">
                <label class="fs-14 fw-500">Mã Bệnh nhân(PID)</label>
                <input
                  v-model="form.person_ref_id"
                  type="text"
                  class="form--input fw-500 h-10"
                  placeholder="Nhập Mã Bệnh nhân"
                />
              </div>

              <div class="col-md-6">
                <div class="fs-14 fw-500">Bác sĩ Chính/Bác sĩ trưởng khoa</div>
                <el-select
                  filterable
                  allow-create
                  v-model="main_doctor"
                  class="w-full"
                  placeholder="Chọn bác sĩ chính"
                >
                  <el-option
                    v-for="item in mainDoctors"
                    :key="item.id + 'md'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>
            <div class="row mt-6">
              <!-- <div class="col-md-6">
                <div class="fs-14 fw-500">Mẫu bệnh án</div>
                <el-select
                  clearable
                  class="w-full"
                  v-model="form.emr_template_id"
                  filterable
                  placeholder="Chọn mẫu bệnh án"
                  remote
                  :remote-method="getListEmrTemplate"
                  :loading="loading"
                  @focus="getListEmrTemplate()"
                  @change="handleChangeTemplate"
                >
                  <el-option
                    v-for="item in emrTemplates"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>-->
              <div class="col-md-6">
                <label class="fs-14 fw-500 required">Tên Hồ sơ Bệnh Án</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form--input fs-14 fw-500 h-10"
                  placeholder="Nhập Tên Hồ sơ Bệnh Án"
                />
              </div>

              <div class="col-md-6">
                <div class="fs-14 fw-500">Bác sĩ Điều trị</div>
                <el-select
                  filterable
                  allow-create
                  v-model="treat_doctor"
                  class="w-full"
                  placeholder="Chọn bác sĩ điều trị"
                >
                  <el-option
                    v-for="item in treatDoctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>

            <div class="mt-6 row">
              <!-- <div class="col-md-6">
                <label class="fs-14 fw-500 required">Tên Hồ sơ Bệnh Án</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form--input fs-14 fw-500 h-10"
                  placeholder="Nhập Tên Hồ sơ Bệnh Án"
                />
              </div>-->
              <div class="col-md-6">
                <div class="fs-14 fw-500">Người hỗ trợ</div>
                <el-select
                  filterable
                  allow-create
                  v-model="supporter_id"
                  class="w-full"
                  placeholder="Chọn người hỗ trợ"
                >
                  <el-option
                    v-for="item in support_doctors"
                    :key="item.id + 'md'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>

            <div class="mt-6 text-sm font-medium flex justify-end">
              <button
                @click="addPerson"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
              >
                <div v-if="!update">Tạo mới</div>
                <div v-else>Cập nhật</div>
              </button>
              <button
                @click="showModalAddBenhAn(false)"
                class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
              >
                <div>Hủy</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // -->
    <div>
      <div class="modal fade" id="modal__update_benhan">
        <div v-loading="loading" class="modal-dialog modal-lg shadow" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ "Chỉnh sửa Bệnh án" }}</h5>
            </div>
            <div class="modal-body txt-grey-900">
              <div class="row">
                <div class="col-md-6">
                  <div class="fs-14 fw-500">Bệnh nhân</div>
                  <el-select
                    class="w-full"
                    disabled
                    v-model="personName"
                    filterable
                    placeholder="Chọn bệnh nhân"
                    @change="setPersonID"
                    remote
                    :remote-method="getPersonsWS"
                    :loading="loading"
                    @focus="getPersonsWS()"
                  >
                    <el-option
                      v-for="item in listPerson"
                      :key="item.id + 'bn'"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <div class="col-md-6">
                  <div class="fs-14 fw-500">Người thân</div>
                  <div class="flex gap-2">
                    <input
                      v-model="guardian.guardian_name"
                      type="text"
                      :readonly="true"
                      class="form--input fw-500 cursor-not-allowed h-10 flex-1"
                    />
                    <div
                      class="flex items-center justify-end h-10 w-fit rounded-md px-2 gap-1 cursor-pointer"
                      style="background-color:#f6f8fb"
                      @click="showModalAddGuardian(true)"
                    >
                      <svg
                        v-if="!guardian.id"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.8333 9.16659H10.8333V4.16659C10.8333 3.94557 10.7455 3.73361 10.5892 3.57733C10.433 3.42105 10.221 3.33325 9.99998 3.33325C9.77897 3.33325 9.567 3.42105 9.41072 3.57733C9.25444 3.73361 9.16665 3.94557 9.16665 4.16659V9.16659H4.16665C3.94563 9.16659 3.73367 9.25438 3.57739 9.41066C3.42111 9.56694 3.33331 9.7789 3.33331 9.99992C3.33331 10.2209 3.42111 10.4329 3.57739 10.5892C3.73367 10.7455 3.94563 10.8333 4.16665 10.8333H9.16665V15.8333C9.16665 16.0543 9.25444 16.2662 9.41072 16.4225C9.567 16.5788 9.77897 16.6666 9.99998 16.6666C10.221 16.6666 10.433 16.5788 10.5892 16.4225C10.7455 16.2662 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0543 10.8333 16.2663 10.7455 16.4226 10.5892C16.5788 10.4329 16.6666 10.2209 16.6666 9.99992C16.6666 9.7789 16.5788 9.56694 16.4226 9.41066C16.2663 9.25438 16.0543 9.16659 15.8333 9.16659Z"
                          fill="currentColor"
                        />
                      </svg>
                      <svg
                        v-else
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.75 9.95357C15.5511 9.95357 15.3603 10.0326 15.2197 10.1732C15.079 10.3139 15 10.5047 15 10.7036V15.2036C15 15.4025 14.921 15.5932 14.7803 15.7339C14.6397 15.8746 14.4489 15.9536 14.25 15.9536H3.75C3.55109 15.9536 3.36032 15.8746 3.21967 15.7339C3.07902 15.5932 3 15.4025 3 15.2036V4.70357C3 4.50466 3.07902 4.31389 3.21967 4.17324C3.36032 4.03259 3.55109 3.95357 3.75 3.95357H8.25C8.44891 3.95357 8.63968 3.87455 8.78033 3.7339C8.92098 3.59325 9 3.40248 9 3.20357C9 3.00466 8.92098 2.81389 8.78033 2.67324C8.63968 2.53259 8.44891 2.45357 8.25 2.45357H3.75C3.15326 2.45357 2.58097 2.69062 2.15901 3.11258C1.73705 3.53453 1.5 4.10683 1.5 4.70357V15.2036C1.5 15.8003 1.73705 16.3726 2.15901 16.7946C2.58097 17.2165 3.15326 17.4536 3.75 17.4536H14.25C14.8467 17.4536 15.419 17.2165 15.841 16.7946C16.2629 16.3726 16.5 15.8003 16.5 15.2036V10.7036C16.5 10.5047 16.421 10.3139 16.2803 10.1732C16.1397 10.0326 15.9489 9.95357 15.75 9.95357ZM4.5 10.5236V13.7036C4.5 13.9025 4.57902 14.0932 4.71967 14.2339C4.86032 14.3746 5.05109 14.4536 5.25 14.4536H8.43C8.5287 14.4541 8.62655 14.4352 8.71793 14.3979C8.80931 14.3606 8.89242 14.3056 8.9625 14.2361L14.1525 9.03857L16.2825 6.95357C16.3528 6.88385 16.4086 6.80089 16.4467 6.7095C16.4847 6.61811 16.5043 6.52008 16.5043 6.42107C16.5043 6.32206 16.4847 6.22403 16.4467 6.13263C16.4086 6.04124 16.3528 5.95829 16.2825 5.88857L13.1025 2.67107C13.0328 2.60077 12.9498 2.54498 12.8584 2.5069C12.767 2.46882 12.669 2.44922 12.57 2.44922C12.471 2.44922 12.373 2.46882 12.2816 2.5069C12.1902 2.54498 12.1072 2.60077 12.0375 2.67107L9.9225 4.79357L4.7175 9.99107C4.64799 10.0611 4.59299 10.1443 4.55567 10.2356C4.51835 10.327 4.49943 10.4249 4.5 10.5236ZM12.57 4.26107L14.6925 6.38357L13.6275 7.44857L11.505 5.32607L12.57 4.26107ZM6 10.8311L10.4475 6.38357L12.57 8.50607L8.1225 12.9536H6V10.8311Z"
                          fill="#20419B"
                        />
                      </svg>
                      <div>{{ guardian.id ? 'Chỉnh sửa':'Thêm mới' }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-6 row">
                <!-- <div class="col-md-6">
                  <label class="fs-14 fw-500 required">Mã Bệnh Án</label>
                  <input
                    v-model="form_update.code"
                    type="text"
                    class="form--input fw-500"
                    placeholder="Nhập Mã Bệnh Án"
                  />
                </div>-->
                <div class="col-md-6">
                  <label class="fs-14 fw-500">Mã Bệnh nhân(PID)</label>
                  <input
                    v-model="form_update.person_ref_id"
                    type="text"
                    class="form--input fw-500"
                    placeholder="Nhập Mã Bệnh nhân"
                  />
                </div>
                <div class="col-md-6">
                  <label class="fs-14 fw-500">Mã điều trị</label>
                  <input
                    v-model="form_update.ref_id"
                    type="text"
                    class="form--input fw-500"
                    placeholder="Nhập mã điều trị"
                  />
                </div>
                <div class="col-md-6 mt-6">
                  <label class="fs-14 fw-500 required">Tên Hồ sơ Bệnh Án</label>
                  <input
                    v-model="form_update.name"
                    type="text"
                    class="form--input fs-16 fw-500"
                    placeholder="Nhập Tên Hồ sơ Bệnh Án"
                  />
                </div>
                <div class="col-md-6 mt-6">
                  <div class="fs-14 fw-500">Bác sĩ Chính/Bác sĩ trưởng khoa</div>
                  <el-select
                    filterable
                    allow-create
                    v-model="main_doctor"
                    class="w-full"
                    placeholder="Chọn bác sĩ chính"
                  >
                    <el-option
                      v-for="item in mainDoctors"
                      :key="item.id + 'md'"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </div>
              </div>

              <div class="mt-6 row">
                <div class="col-md-6">
                  <div class="fs-14 fw-500">Bác sĩ Điều trị</div>
                  <el-select
                    filterable
                    allow-create
                    v-model="treat_doctor"
                    class="w-full"
                    placeholder="Chọn bác sĩ điều trị"
                  >
                    <el-option
                      v-for="item in treatDoctors"
                      :key="item.id + 'td'"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </div>
                <div class="col-md-6">
                  <div class="fs-14 fw-500">Người hỗ trợ</div>
                  <el-select
                    filterable
                    allow-create
                    v-model="supporter_id"
                    class="w-full"
                    placeholder="Chọn người hỗ trợ"
                  >
                    <el-option
                      v-for="item in support_doctors"
                      :key="item.id + 'md'"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </div>
              </div>

              <div class="mt-6 text-sm font-medium flex justify-end">
                <button
                  @click="updatePerson"
                  class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
                >
                  <div>Lưu</div>
                </button>

                <button
                  @click="showModalUpdateBenhAn(false)"
                  class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
                >
                  <div>Hủy</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!noModalAddPatient">
      <ModalAddPerson ref="ModalAddPerson" @refresh="completeAddPerson"></ModalAddPerson>
    </div>
    <ModalAddGuardian
      v-if="person && person.id && badt && badt.id"
      ref="ModalAddGuardian"
      :personId="person.id"
      :mrId="badt.id"
      :type="guardian.id?'update':'create'"
      :guardianData="guardian"
      @onCreatedSuccess="handleAddGuardianSuccess"
      @onUpdateSuccess="handleAddGuardianSuccess"
    />
  </div>
</template>
<script>
import { ButtonHoDo } from '@/components/Button'
import statusBADT from '@/constants/statusBADT'
import ModalAddPerson from '../../components/Person/ModalAddPerson'
import ModalAddGuardian from '../../components/ModalAddGuardian.vue'
import moment from 'moment'

export default {
  name: 'ModalAddBenhAn',
  components: { ModalAddPerson, ButtonHoDo, ModalAddGuardian },
  props: ['update', 'patientParent', 'noModalAddPatient', 'badt'],
  data () {
    return {
      listPerson: [],
      form: {
        person_id: null,
        name: '',
        emr_template_id: null
      },
      form_update: {
        main_doctor: '',
        treat_doctor: '',
        supporter_id: '',
        name: ''
      },
      personName: null,
      person: null,
      loading: false,
      doctors: [],
      main_doctor: null,
      treat_doctor: null,
      supporter_id: null,
      statusBADT,
      admission_hospital_date: null,
      guardian: {},
      emrTemplates: []
    }
  },
  computed: {
    mainDoctors () {
      return this.doctors.filter(
        doctor =>
          [0, 2].includes(doctor.type) &&
          (!this.treat_doctor || this.treat_doctor !== doctor.id) &&
          (!this.supporter_id || this.supporter_id !== doctor.id)
      )
    },
    treatDoctors () {
      return this.doctors.filter(
        doctor =>
          [0, 2].includes(doctor.type) &&
          (!this.main_doctor || this.main_doctor !== doctor.id) &&
          (!this.supporter_id || this.supporter_id !== doctor.id)
      )
    },
    support_doctors () {
      return this.doctors.filter(
        doctor =>
          (!this.main_doctor || this.main_doctor !== doctor.id) &&
          (!this.treat_doctor || this.treat_doctor !== doctor.id)
      )
    }
  },
  watch: {
    patientParent (data) {
      this.completeAddPerson(data)
    },
    badt: {
      deep: true,
      handler (item) {
        this.handleMR()
      }
    }
  },

  mounted () {},
  methods: {
    handleMR () {
      if (this.badt) {
        let item = this.badt
        this.main_doctor =
          (item.main_doctor && item.main_doctor.id) || item.main_doctor_name
        this.treat_doctor =
          (item.treat_doctor && item.treat_doctor.id) || item.treat_doctor_name
        this.supporter_id = item.supporter_id || item.supporter_name
        this.form_update.name = item.medical_record_name
        this.form_update.main_doctor_name = item.main_doctor_name
        this.form_update.treat_doctor_name = item.treat_doctor_name
        this.form_update.supporter_name = item.supporter_name

        this.form_update.ref_id = item.ref_id
        this.form_update.person_ref_id = item.person_ref_id
        this.personName = item.person?.name
        this.person = item.person || {}
        this.form.person_id = item.person?.ida
        this.form.person_ref_id = item?.pid
        this.admission_hospital_date = item.person?.admission_hospital_date
        this.handleGetGuardian(this.badt)
      }
    },
    async getDoctors () {
      const clinic_id = this.$clinicId
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
      this.doctors = res.data?.data
    },
    async putMedicalRecord () {
      let self = this

      if (
        !self.form_update.name ||
        !self.form_update.name.length
        // ||
        // !self.form_update.person_ref_id ||
        // !self.form_update.person_ref_id.length
      ) {
        alert('Vui lòng điền đầy đủ thông tin')
        return
      }

      if (isNaN(self.main_doctor)) {
        self.form_update.main_doctor_name = self.main_doctor
        self.form_update.main_doctor = null
      } else {
        self.form_update.main_doctor_name = null
        self.form_update.main_doctor = self.main_doctor
      }
      if (isNaN(self.treat_doctor)) {
        self.form_update.treat_doctor_name = self.treat_doctor
        self.form_update.treat_doctor = null
      } else {
        self.form_update.treat_doctor_name = null
        self.form_update.treat_doctor = self.treat_doctor
      }

      if (isNaN(self.supporter_id)) {
        self.form_update.supporter_name = self.supporter_id
        self.form_update.supporter_id = null
      } else {
        const supporterInfo =
          this.support_doctors.find(item => item.id === self.supporter_id) ||
          {}
        self.form_update.supporter_name = supporterInfo.name
        self.form_update.supporter_id = self.supporter_id
      }
      try {
        this.loading = true
        const params = {
          main_doctor: self.form_update.main_doctor,
          treat_doctor: self.form_update.treat_doctor,
          supporter_id: self.form_update.supporter_id,
          supporter_name: self.form_update.supporter_name,
          main_doctor_name: self.form_update.main_doctor_name,
          treat_doctor_name: self.form_update.treat_doctor_name,
          medical_record_name: self.form_update?.name,
          ref_id: self.form_update.ref_id,
          person_ref_id: self.form_update.person_ref_id
        }

        if (params.treat_doctor) {
          params.status = 1
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .putMedicalRecord(self.badt.id, params)
        self.$emit('refresh')
        self.showModalUpdateBenhAn(false)
        self.$toast.open({
          message: 'Cập nhật thành công',
          type: 'success'
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    showModalAddPerson (show) {
      this.$refs.ModalAddPerson.openDialog()
    },
    showModalAddGuardian (show) {
      console.log(show)
      window.$('#modalAddGuardian').modal(show ? 'show' : 'hide')
    },
    setPersonID (value) {
      this.form.person_id = value

      const personInfo = this.listPerson.find(item => item.id === value)
      if (!personInfo) return
      this.form_update.person_ref_id = personInfo?.pid
      this.form.person_ref_id = personInfo?.pid
      this.form.ref_id = personInfo.doc_no

      this.admission_hospital_date = personInfo?.admission_hospital_date
    },
    showModalAddBenhAn (show) {
      window.$('#modal__add_benhan').modal(show ? 'show' : 'hide')

      if (!show) {
        this.main_doctor = ''
        this.treat_doctor = ''
        this.form.name = ''
        this.form.person_ref_id = ''
        this.form.ref_id = ''
        this.form.main_doctor_name = ''
        this.form.treat_doctor_name = ''
        this.admission_hospital_date = ''

        if (!this.patientParent) {
          this.form.person_id = null
          this.personName = null
          this.person = null
          this.form.emr_template_id = null
        }
      }
    },
    addPerson () {
      if (!this.update) {
        this.postBADT()
      }
    },
    updatePerson () {
      this.putMedicalRecord()
    },
    async postBADT () {
      let self = this

      if (!self.$globalClinic && !self.$globalClinic.id) return
      if (
        !self.form.name ||
        !self.form.name.length ||
        !self.form.person_id
        // ||
        // !self.form.person_ref_id ||
        // !self.form.person_ref_id.length
      ) {
        alert('Vui lòng điền đầy đủ thông tin')
        return
      }

      self.current_page = parseInt(self.$route.query.page) || 1
      if (isNaN(self.main_doctor)) {
        self.form.main_doctor_name = self.main_doctor
        self.form.main_doctor = null
      } else {
        self.form.main_doctor_name = null
        self.form.main_doctor = self.main_doctor
      }
      if (isNaN(self.treat_doctor)) {
        self.form.treat_doctor_name = self.treat_doctor
        self.form.treat_doctor = null
      } else {
        self.form.treat_doctor_name = null
        self.form.treat_doctor = self.treat_doctor
      }

      if (isNaN(self.supporter_id)) {
        self.form_update.supporter_name = self.supporter_id
        self.form_update.supporter_id = null
      } else {
        const supporterInfo =
          this.support_doctors.find(item => item.id === self.supporter_id) ||
          {}
        self.form_update.supporter_name = supporterInfo.name
        self.form_update.supporter_id = self.supporter_id
      }

      const params = {
        clinic_id: self.$globalClinic.id,
        main_doctor: self.form.main_doctor,
        treat_doctor: self.form.treat_doctor,
        supporter_id: self.form_update.supporter_id,
        supporter_name: self.form_update.supporter_name,
        treat_doctor_name: self.form.treat_doctor_name,
        main_doctor_name: self.form.main_doctor_name,
        ref_id: self.form.ref_id,
        person_ref_id: self.form.person_ref_id,
        person_id: self.form.person_id,
        medical_record_name: self.form.name
      }

      if (params.treat_doctor) {
        params.status = 1
      }
      if (self.form.emr_template_id) {
        params.emr_template_id = self.form.emr_template_id
      }

      this.loading = true
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .postBADT(params, false, false)
        .then(r => {
          self.$emit('refresh')

          self.showModalAddBenhAn(false)
          return r
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: 'Có lỗi trong quá trình tạo',
            type: 'warning'
          })
          return false
        })
      this.loading = false

      return r
    },
    completeAddPerson (data) {
      this.person = data
      this.form.person_ref_id = data?.pid
      this.personName = data?.name
      this.admission_hospital_date = data?.admission_hospital_date
      this.form.person_id = this.person.id
      // this.getPersonWithID()
    },
    async getPersonWithID () {
      if (!this.person) return
      this.form.person_id = this.person.id
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .getPersonWithID(this.person.id)
        .then(r => {
          this.personName = r.data?.name
          return r
        })
        .catch(e => {
          console.log(e)
          return false
        })
      return r
    },
    async getPersonsWS (search = null) {
      const params = {
        limit: 50,
        clinic_id: this.$globalClinic?.id
      }
      if (search) {
        params.search = search
      }
      this.loading = true
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .getPersonsV2(params)
        .then(r => {
          this.listPerson = r.data?.data
          return r
        })
        .catch(e => {
          console.log(e)
          return false
        })
        .finally(() => {
          this.loading = false
        })
      return r
    },

    showModalUpdateBenhAn (s) {
      window.$('#modal__add_benhan').modal(s ? 'show' : 'hide')
      window.$('#modal__update_benhan').modal(s ? 'show' : 'hide')
    },
    handleAddGuardianSuccess () {
      this.handleGetGuardian()
      this.showModalAddGuardian(false)
    },
    async handleGetGuardian (badtData) {
      if (!this.badt?.id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getGuardian(this.badt.id)
        this.guardian = response.data?.data || {}
        this.$store.commit('setPerson', {
          person: {
            ...this.person,
            status: badtData.status,
            medical_record_name: badtData.medical_record_name,
            treat_doctor: badtData.treat_doctor_name,
            main_doctor: badtData.main_doctor_name,
            ref_id: badtData.ref_id,
            person_ref_id: badtData.person_ref_id,
            admission_room: badtData.medical_record_master?.admission_room,
            admission_bed: badtData.medical_record_master?.admission_bed,
            guardian: { ...this.guardian }
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getListEmrTemplate (search) {
      if (!this.$globalClinic && !this.$globalClinic.id) return

      let self = this
      const params = {
        workspace_id: this.$globalClinic.id,
        sort_by: 'desc'
      }
      if (search) {
        params.keyword = search
      }
      this.loading = true
      const r = await this.$rf
        .getRequest('DoctorRequest')
        .getListEmrTemplate(params)
        .then(r => {
          self.emrTemplates = r.data?.data?.data || []
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
      return r
    },
    handleChangeTemplate (selectedTem) {
      const emrTemInfo =
        this.emrTemplates.find(item => item.id === selectedTem) || {}
      this.form.name = emrTemInfo?.name
    },
    handleGetPatientLabel (item) {
      const name = item?.name || ''

      if (!item.birthday) return name
      const date = moment(item.birthday).format('YYYY')
      const dateLabel = date ? `-${date}` : ''
      return `${name} ${dateLabel}`
    },
    async openModal (show) {
      if (show) {
        this.getDoctors()
        this.getPersonsWS()
        this.handleMR()
        if (!this.badt) {
          this.getListEmrTemplate()
        }

        window.$('#modal__update_benhan').modal(show ? 'show' : 'hide')
      }
    }
  }
}
</script>
<style scoped></style>
