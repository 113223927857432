<template>
  <div>
    <div class="modal fade" id="modal_assign_benh_an">
      <div
        v-loading="loading"
        class="modal-dialog modal-lg shadow"
        role="document"
      >
        <div v-if="badtDetail && badtDetail.person" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">PHÂN CÔNG CÔNG VIỆC</h5>
          </div>
          <div class="modal-body txt-grey-900">
            <div class="flex">
              <div class="col-md-6 flex">
                <span class="fs-14 fw-500">Bệnh nhân: &nbsp;</span>
                <span class="fs-14 font-bold">{{
                  badtDetail.person.name
                }}</span>
              </div>
            </div>
            <div class="mt-6 flex">
              <div class="col-md-6 flex">
                <label class="fs-14 fw-500">Mã Bệnh nhân(PID): &nbsp;</label>
                <span class="fs-14 fw-500">{{ badtDetail.person_ref_id }}</span>
              </div>
              <div class="col-md-6">
                <label class="fs-14 fw-500">Mã điều trị: &nbsp;</label>
                <span class="fs-14 fw-500">{{ badtDetail.ref_id }}</span>
              </div>
            </div>
            <div class="mt-6 flex">
              <div class="col-md-6 mt-6">
                <label class="fs-14 fw-500">Bệnh Án: &nbsp;</label>
                <span>{{ badtDetail.medical_record_name }}</span>
              </div>
              <div class="col-md-6">
                <div class="fs-14 fw-500">Bác sĩ Chính/Bác sĩ trưởng khoa</div>
                <el-select
                  filterable
                  allow-create
                  v-model="main_doctor"
                  class="w-full"
                  placeholder="Chọn bác sĩ chính"
                >
                  <el-option
                    v-for="item in mainDoctors"
                    :key="item.id + 'md'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>

            <div class="mt-6 row">
              <div class="col-md-6">
                <div class="fs-14 fw-500">Bác sĩ Điều trị</div>
                <el-select
                  filterable
                  allow-create
                  v-model="treat_doctor"
                  class="w-full"
                  placeholder="Chọn bác sĩ điều trị"
                >
                  <el-option
                    v-for="item in treatDoctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="col-md-6">
                <div class="fs-14 fw-500">Người hỗ trợ</div>
                <el-select
                  filterable
                  allow-create
                  v-model="supporter_id"
                  class="w-full"
                  placeholder="Chọn người hỗ trợ"
                >
                  <el-option
                    v-for="item in support_doctors"
                    :key="item.id + 'md'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>

            <div class="mt-6 text-sm font-medium flex justify-end">
              <button
                @click="updatePerson"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
              >
                <div>Lưu</div>
              </button>
              <button
                @click="showModalAssignBenhAn(false)"
                class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
              >
                <div>Hủy</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonHoDo } from '@/components/Button'

export default {
  name: 'ModalAssignBenhAn',
  components: { ButtonHoDo },
  props: ['badtDetail'],
  data () {
    return {
      loading: false,
      doctors: [],
      main_doctor: null,
      treat_doctor: null,
      supporter_id: null
    }
  },
  computed: {
    mainDoctors () {
      return this.doctors.filter(
        (doctor) =>
          [0, 2].includes(doctor.type) &&
          (!this.treat_doctor || this.treat_doctor !== doctor.id) &&
          (!this.supporter_id || this.supporter_id !== doctor.id)
      )
    },
    treatDoctors () {
      return this.doctors.filter(
        (doctor) =>
          [0, 2].includes(doctor.type) &&
          (!this.main_doctor || this.main_doctor !== doctor.id) &&
          (!this.supporter_id || this.supporter_id !== doctor.id)
      )
    },
    support_doctors () {
      return this.doctors.filter(
        (doctor) =>
          (!this.main_doctor || this.main_doctor !== doctor.id) &&
          (!this.treat_doctor || this.treat_doctor !== doctor.id)
      )
    }
  },
  watch: {
    badtDetail () {
      this.main_doctor =
        (this.badtDetail.main_doctor && this.badtDetail.main_doctor?.id) ||
        this.badtDetail.main_doctor_name
      this.treat_doctor =
        (this.badtDetail.treat_doctor && this.badtDetail.treat_doctor?.id) ||
        this.badtDetail.treat_doctor_name
      this.supporter_id =
        this.badtDetail.supporter_id || this.badtDetail.treat_doctor_name
    }
  },
  mounted () {},
  methods: {
    async getDoctors () {
      const clinic_id = this.$clinicId
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
      this.doctors = res.data?.data
    },
    async updatePerson () {
      let self = this

      try {
        const participants = []
        this.loading = true
        const params = {
          main_doctor: self.main_doctor,
          treat_doctor: self.treat_doctor
        }
        if (isNaN(self.main_doctor)) {
          params.main_doctor_name = self.main_doctor
          params.main_doctor = null
        } else {
          params.main_doctor_name = null
          params.main_doctor = self.main_doctor
          const main_user_id = this.doctors?.find(
            (item) => item?.id === self.main_doctor
          )?.user_id
          participants.push(main_user_id)
        }
        if (isNaN(self.treat_doctor)) {
          params.treat_doctor_name = self.treat_doctor
          params.treat_doctor = null
        } else {
          params.treat_doctor_name = null
          params.treat_doctor = self.treat_doctor
          const treat_user_id = this.doctors?.find(
            (item) => item?.id === self.treat_doctor
          )?.user_id
          participants.push(treat_user_id)
        }

        if (isNaN(self.supporter_id)) {
          params.supporter_name = self.supporter_id
          params.supporter_id = null
        } else {
          const supporterInfo =
            this.support_doctors.find(
              (item) => item.id === self.supporter_id
            ) || {}

          params.supporter_name = supporterInfo.name
          params.supporter_id = self.supporter_id

          participants.push(supporterInfo.user_id)
        }

        if (params.treat_doctor) {
          params.status = 1
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .putMedicalRecord(self.badtDetail.id, params)

        await this.handleAddEmrParticipants(participants)

        self.$emit('onUpdateSuccess')
        self.showModalAssignBenhAn(false)
        self.$toast.open({
          message: 'Cập nhật thành công',
          type: 'success'
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    showModalAssignBenhAn (show) {
      window.$('#modal_assign_benh_an').modal(show ? 'show' : 'hide')
    },
    openModal () {
      window.$('#modal_assign_benh_an').modal('show')

      this.getDoctors()
    },
    async handleAddEmrParticipants (participants) {
      if (!participants?.length || !this.badtDetail?.id) return
      try {
        const params = {
          emr_id: this.badtDetail.id,
          user_ids: participants
        }
        await this.$rf.getRequest('HIDRequest').addEmrParticipant(params)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style scoped></style>
