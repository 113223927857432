<template>
  <div class="mt-3 cs-container relative">
    <div class="d-print-none">
      <button class="btn bg-pri text-white ml-auto d-print-none absolute top-2 right-2 px-3 mx-2" @click="handlePrint">
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.8332 4.66667H13.9998V0.5H3.99984V4.66667H3.1665C1.78317 4.66667 0.666504 5.78333 0.666504 7.16667V12.1667H3.99984V15.5H13.9998V12.1667H17.3332V7.16667C17.3332 5.78333 16.2165 4.66667 14.8332 4.66667ZM5.6665 2.16667H12.3332V4.66667H5.6665V2.16667ZM12.3332 13.8333H5.6665V10.5H12.3332V13.8333ZM13.9998 10.5V8.83333H3.99984V10.5H2.33317V7.16667C2.33317 6.70833 2.70817 6.33333 3.1665 6.33333H14.8332C15.2915 6.33333 15.6665 6.70833 15.6665 7.16667V10.5H13.9998Z"
            fill="white" />
          <path
            d="M13.9998 8.41667C14.4601 8.41667 14.8332 8.04357 14.8332 7.58333C14.8332 7.1231 14.4601 6.75 13.9998 6.75C13.5396 6.75 13.1665 7.1231 13.1665 7.58333C13.1665 8.04357 13.5396 8.41667 13.9998 8.41667Z"
            fill="white" />
        </svg>
        {{ $t('multidisciplinary_board.lbl_print') }}
      </button>
      <button class="btn bg-pri text-white ml-auto d-print-none absolute top-14 right-2 px-3 mx-2"
        @click="handleGenerationQR">
        <svg fill="currentColor" width="18px" height="18px" viewBox="0 0 32 32" id="icon"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="24" y="26" width="2" height="2" transform="translate(-2 52) rotate(-90)" />
          <rect x="18" y="22" width="2" height="2" transform="translate(-4 42) rotate(-90)" />
          <polygon points="18 30 22 30 22 28 20 28 20 26 18 26 18 30" />
          <rect x="24.9999" y="23" width="4" height="2" transform="translate(2.9999 50.9999) rotate(-90)" />
          <polygon points="28 26 30 26 30 30 26 30 26 28 28 28 28 26" />
          <polygon points="26 20 26 18 30 18 30 22 28 22 28 20 26 20" />
          <polygon points="24 20 22 20 22 24 20 24 20 26 24 26 24 20" />
          <rect x="19" y="17" width="2" height="4" transform="translate(1 39) rotate(-90)" />
          <rect x="6" y="22" width="4" height="4" />
          <path d="M14,30H2V18H14ZM4,28h8V20H4Z" />
          <rect x="22" y="6" width="4" height="4" />
          <path d="M30,14H18V2H30ZM20,12h8V4H20Z" />
          <rect x="6" y="6" width="4" height="4" />
          <path d="M14,14H2V2H14ZM4,12h8V4H4Z" />
          <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" fill="none" width="32"
            height="32" />
        </svg>
        <span>{{ $t('QR') }}</span>
      </button>

      <svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" @click="handleBack"
        class="absolute top-1/2 -translate-x-1/2 left-4 text-gray-400 hover:text-blue-900 cursor-pointer d-print-none">
        <path
          d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
          stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" />
        <path d="M18 11.5L13 16L18 20.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
        @click="handleViewDetail"
        class="absolute top-1/2 -translate-x-1/2 right-4 text-gray-400 hover:text-blue-900 cursor-pointer d-print-none">
        <path
          d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
          stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" />
        <path d="M14.5 11.5L19.5 16L14.5 20.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
    <div class="text-black">
      <div class="cs-body mx-auto px-14">
        <div class="cs-wrapper">
          <div class="row cs-p-header">
            <div class="col-sm-6 flex flex-col items-start justify-start">
              <div class="fs-22 uppercase">
                <div class="text-center font-bold">
                  {{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.authority_in_charge : ''
                  }}
                </div>
                <div class="font-bold">{{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.name
        : '' }}</div>
              </div>
            </div>
            <div class="col-sm-6 flex flex-col items-end justify-center">
              <div class="fs-24 cs-info">
                <div class="cs-info-item cs-border-b">
                  <div class="font-bold uppercase">Số hồ sơ</div>
                  <div class="fs-20 font-normal">
                    <AppContentNone :content="emrData.ref_id" />
                  </div>
                </div>
                <div class="cs-info-item">
                  <div class="font-bold uppercase">Mã bệnh nhân</div>
                  <div class="fs-20 font-normal">
                    <AppContentNone :content="emrData.person && emrData.person.pid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cs-p-title mt-10 uppercase fs-56 font-bold text-center">Bệnh án</div>
          <div class="uppercase font-normal fs-24 text-center">
            <span>Năm&nbsp;</span>
            <span>{{ currentYear }}</span>
          </div>
          <div class="mt-4 fs-18 cs-patient">
            <div class="row">
              <div class="col-sm-5 flex">
                <div class="uppercase font-normal mr-2">Họ Và Tên:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="(emrData.person && emrData.person.name)" />
                </div>
              </div>
              <div class="col-sm-4 flex">
                <span class="uppercase font-normal mr-1">Năm sinh:</span>
                <div class="flex-1 font-bold">
                  <AppContentNone :content="getYear(emrData.person && emrData.person.birthday)" />
                </div>
              </div>
              <div class="col-sm-3 flex">
                <div class="uppercase font-normal mr-1">Giới tính:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="(emrData.person && getGender(emrData.person.gender))" />
                </div>
              </div>
              <div class="col-12 flex" :class="emrData.person && emrData.person.career ? 'mt-2' : 'mt-3'">
                <div class="uppercase font-normal mr-1">Nghề nghiệp:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="(emrData.person && emrData.person.career)" />
                </div>
              </div>
              <div class="col-12 mt-2 flex">
                <div class="uppercase font-normal mr-1">Địa chỉ:</div>
                <div class="font-bold flex-1">
                  <AppContentNone :content="emrData.person && getAddress(emrData.person)" />
                </div>
              </div>
            </div>
          </div>
          <div class="row fs-18 cs-mt flex flex-col sm:flex-row gap-4 mx-auto cs-p-data">
            <div class="col-sm-3 cs-box px-3">
              <div class="uppercase font-normal">Nhập viện</div>
              <div class="font-bold mt-2">
                <div class="flex flex-wrap items-center">
                  <div class="mr-1 font-normal">Ngày:</div>
                  <div class="mr-1 font-normal cs-font-small" :class="!getReceptionAt(emrData) && 'flex-1'">
                    <AppDateNone :dateString="getReceptionAt(emrData)" />
                  </div>
                </div>
                <div class="mt-1 flex flex-wrap">
                  <div class="mr-1 font-normal">Viện/Khoa:</div>
                  <div class="mr-1 font-normal"
                    :class="((!emrData.medical_record_master || !emrData.medical_record_master.reception_location) && (!$globalClinic || !$globalClinic.name)) && 'flex-1'">
                    <AppContentNone
                      :content="((emrData.medical_record_master && emrData.medical_record_master.reception_location) || ($globalClinic && $globalClinic.name))" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-5 cs-box px-3">
              <div class="uppercase font-normal">Chuyển Viện/Khoa</div>
              <div class="font-bold mt-2">
                <div class="flex flex-wrap" :class="transferDataFrom.date && 'items-center'">
                  <div class="mr-1 font-normal">Ngày:</div>

                  <div class="mr-1 font-normal cs-font-small" :class="!transferDataFrom.date && 'flex-1 '">
                    <AppDateNone :dateString="transferDataFrom.date" />
                  </div>
                </div>
                <div class="mt-1 font-normal flex flex-wrap">
                  <div class="mr-1">Viện/Khoa:</div>
                  <div class="mr-1 font-normal" :class="!transferDataFrom.location && 'flex-1'">
                    <AppContentNone :content="(transferDataFrom.location)" />
                  </div>
                </div>
                <div class="flex flex-wrap" :class="transferDataTo.date && 'items-center'">
                  <div class="mr-1 font-normal">Ngày:</div>
                  <div class="mr-1 font-normal cs-font-small" :class="!transferDataTo.date && 'flex-1 '">
                    <AppDateNone :dateString="transferDataTo.date" />
                  </div>
                </div>
                <div class="mt-1 flex flex-wrap">
                  <div class="mr-1 font-normal">Viện/Khoa:</div>
                  <div class="mr-1 font-normal" :class="!transferDataTo.location && 'flex-1'">
                    <AppContentNone :content="(transferDataTo.location)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-3 cs-box px-3">
              <div class="uppercase font-normal">Xuất Viện/Khoa</div>
              <div class="font-bold mt-2">
                <div class="flex flex-wrap items-center">
                  <div class="mr-1 font-normal">Ngày:</div>
                  <div class="mr-1 font-normal cs-font-small"
                    :class="(!emrData.medical_record_master || !getDate(emrData.medical_record_master.discharge_at)) && 'flex-1'">
                    <AppDateNone
                      :dateString="emrData.medical_record_master && getDate(emrData.medical_record_master.discharge_at)" />
                  </div>
                </div>
                <div class="mt-1 flex flex-wrap">
                  <div class="mr-1 font-normal">Viện/Khoa:</div>
                  <div class="mr-1 font-normal"
                    :class="(!emrData.medical_record_master || !emrData.medical_record_master.transfer_to) && 'flex-1'">
                    <AppContentNone
                      :content="(emrData.medical_record_master && emrData.medical_record_master.transfer_to)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row fs-18 cs-mt flex gap-4 mx-auto cs-patient-code">
            <div class="col-sm-3"></div>
            <div class="col-sm-5 cs-box px-3">
              <div class="uppercase font-normal flex">
                <div class="mr-1">Mã Bệnh:</div>
                <div class="flex-1">
                  <AppContentNone
                    :content="(emrData.medical_record_master && emrData.medical_record_master.treat_dept_main_code)" />
                </div>
              </div>
              <div class="uppercase font-normal flex">
                <div class="mr-1">Số phim XQ:</div>
                <div class="flex-1">
                  <AppContentNone :content="(file.XQ)" />
                </div>
              </div>
              <div class="uppercase font-normal flex">
                <div class="mr-1">Số phim CT:</div>

                <div class="flex-1">
                  <AppContentNone :content="(file.CT)" />
                </div>
              </div>
              <div class="uppercase font-normal flex">
                <div class="mr-1">Số phim khác:</div>
                <div class="flex-1">
                  <AppContentNone :content="(file.OTHER)" />
                </div>
              </div>
            </div>
            <div class="col-sm-3"></div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="`QR Code BAĐT: ${emrData.person && emrData.person.name}`" :visible.sync="qrCodeShow" width="50%"
      center>
      <div id="qrCodeEmr">
        <div class="flex flex-col items-center justify-center p-4 border-2 border-solid border-black h-full">
          <div class="fs-22 mb-3 text-black">
            <div class="fs-22 uppercase">
              <div class="text-center font-bold">
                <span>{{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.authority_in_charge :
        ''
                  }}</span>
              </div>
              <div class="font-bold">
                <span>{{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.name : '' }}</span>
              </div>
            </div>
          </div>
          <div class="border-2 border-solid border-black p-2 mb-3">
            <QrcodeVue renderAs="svg" :value="urlDomain" :size="qrSize" :level="qrLevel"></QrcodeVue>
          </div>
          <div class="fs-22 mb-2 text-black">
            <label class="font-bold uppercase mr-1">Số hồ sơ:</label>
            <span>{{ emrData && emrData.ref_id }}</span>
          </div>
          <div class="fs-22 mb-2 text-black">
            <label class="font-bold uppercase mr-1">Mã bệnh nhân:</label>
            <span>{{ emrData && emrData.person && emrData.person.pid }}</span>
          </div>
        </div>
      </div>

      <div class="d-print-none flex justify-center mt-4">
        <button class="btn bg-pri text-white d-print-none mx-2" @click="handlePrintQR">
          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.8332 4.66667H13.9998V0.5H3.99984V4.66667H3.1665C1.78317 4.66667 0.666504 5.78333 0.666504 7.16667V12.1667H3.99984V15.5H13.9998V12.1667H17.3332V7.16667C17.3332 5.78333 16.2165 4.66667 14.8332 4.66667ZM5.6665 2.16667H12.3332V4.66667H5.6665V2.16667ZM12.3332 13.8333H5.6665V10.5H12.3332V13.8333ZM13.9998 10.5V8.83333H3.99984V10.5H2.33317V7.16667C2.33317 6.70833 2.70817 6.33333 3.1665 6.33333H14.8332C15.2915 6.33333 15.6665 6.70833 15.6665 7.16667V10.5H13.9998Z"
              fill="white" />
            <path
              d="M13.9998 8.41667C14.4601 8.41667 14.8332 8.04357 14.8332 7.58333C14.8332 7.1231 14.4601 6.75 13.9998 6.75C13.5396 6.75 13.1665 7.1231 13.1665 7.58333C13.1665 8.04357 13.5396 8.41667 13.9998 8.41667Z"
              fill="white" />
          </svg>
          {{ $t('multidisciplinary_board.lbl_print') }}
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import AppContentNone from '../../components/Common/AppContentNone.vue'
import AppDateNone from '../../components/Common/AppDateNone.vue'
import appUtils from '../../utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'

export default {
  name: 'WrapperBenhAn',
  props: {
    emrId: Number
  },
  components: { AppContentNone, AppDateNone, QrcodeVue },
  mixins: [mixinGetPersonInfo],
  data () {
    return {
      loading: false,
      emrData: {},
      qrCodeShow: false,
      qrSize: 300,
      qrLevel: 'L', // L, M, Q, H
      urlDomain: null
    }
  },
  watch: {
    emrId () {
      this.getDetailBADT()
    }
  },
  computed: {
    currentYear () {
      return window.moment().format('YYYY')
    },
    transferDataFrom () {
      const data = this.emrData.medical_record_master?.inouts?.[1]
      return {
        date: this.getDate(data?.start_time),
        location: data?.department
      }
    },
    transferDataTo () {
      const data = this.emrData.medical_record_master?.inouts?.[2]
      return {
        date: this.getDate(data?.start_time),
        location: data?.department
      }
    },
    file () {
      const data = this.emrData.medical_record_master?.files || []
      return {
        XQ: data?.[0]?.count,
        CT: data?.[1]?.count,
        OTHER: data?.[4]?.count
      }
    }
  },
  created () {
    this.getDetailBADT()
  },
  methods: {
    async getDetailBADT () {
      if (!this.$route.params.id) {
        return
      }
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDetailBADT(this.$route.params.id)
      this.emrData = res.data
    },
    handleViewDetail () {
      this.$router
        .push({ name: 'ERMTreeView', params: { id: this.$route.params.id } })
        .catch(e => { })
    },
    getYear (birthday) {
      return (
        birthday &&
        (window.moment(birthday).year() ? window.moment(birthday).year() : '')
      )
    },
    getGender (gender) {
      return appUtils.getGender(gender)
    },
    getDate (date) {
      if (!date) {
        return
      }
      const array = window.moment(date).format('HH:mm DD-MM-YYYY')
      const hour = array.split(' ')[0].split(':')
      const date2 = array.split(' ')[1].split('-')
      return `${hour[0]} giờ ${hour[1]} phút, ngày ${date2[0]}/${date2[1]}/${date2[2]}`
    },
    getReceptionAt (data) {
      return data.medical_record_master &&
        data.medical_record_master.reception_at
        ? this.getDate(data.medical_record_master.reception_at)
        : data?.person?.admission_hospital_date
          ? this.getDate(data?.person?.admission_hospital_date)
          : ''
    },
    handlePrint () {
      window.print()
    },
    handlePrintQR () {
      this.printMe(document.getElementById('qrCodeEmr'))
    },
    printMe (query) {
      let printWindow = window.open('', 'PRINT', 'height=720,width=1024')
      printWindow.document.write(appUtils.templateHTMLPrint({
        title: document.title,
        bodyHTML: query.innerHTML,
        appendStyle: true
      }))

      setTimeout(function () {
        printWindow.document.close() // necessary for IE >= 10
        printWindow.focus() // necessary for IE >= 10*/
        printWindow.print()
        printWindow.close()
      }, 3000) // wait for images to load inside window

      return true
    },
    handleGenerationQR () {
      const urlDomain = window.location.href
      const emrID = this.emrId || this.emrData?.id
      const token = appUtils.getLocalToken()
      const org_id = this.$globalClinic?.org_id
      const ws_id = this.$globalClinic?.id

      let domain = ''

      if (!token || (!ws_id && !org_id)) return ''

      if (!emrID) {
        this.$message({
          type: 'error',
          message: `Không thể tạo QR Code`
        })
        return
      }

      if (urlDomain.includes('localhost')) {
        domain = `http://192.168.5.100:9051/#/emr-detail/${emrID}?ws_id=${ws_id}&org_id=${org_id}`
      } else {
        if (process.env.NODE_ENV === 'development') {
          domain = `https://beta.v2.dev.hodo.app/#/emr-detail/${emrID}?ws_id=${ws_id}&org_id=${org_id}`
        } else {
          domain = `https://beta.v2.hodo.app/#/emr-detail/${emrID}?ws_id=${ws_id}&org_id=${org_id}`
        }
      }

      this.urlDomain = domain
      console.log(domain)

      this.qrCodeShow = true
    },
    getDateObj (date) {
      if (!date) return {}

      const day = window.moment(date).year() ? window.moment(date).year() : ''

      const month = window.moment(date).year()
        ? window.moment(date).year()
        : ''

      const year = window.moment(date).year() ? window.moment(date).year() : ''

      return {
        day,
        month,
        year
      }
    },
    handleBack () {
      this.$router.replace({ name: 'ListBenhAn' })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  padding: 0 !important;
}

.cs-body {
  max-width: 1000px;
  width: 100%;
  margin: 40px 0;
}

.cs-wrapper {
  border: 2px solid #000;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.cs-info {
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-width: 350px;
}

.cs-info-item {
  width: 100%;
  text-align: center;
  padding: 8px;
}

.cs-border-b {
  border-bottom: 1px solid #000;
}

.cs-box {
  border: 1px solid #000;
  padding: 8px;
}

.cs-mt {
  margin-top: 40px;
}

.content-none {
  width: 100%;
  border-bottom: 2px dotted;
}

.cs-font-small {
  font-size: 17px;
}

@media print {
  .cs-body {
    max-width: unset;
    width: 100%;
    margin: 0px;
    height: 970px;
  }

  .cs-body * {
    font-size: 14px !important;
  }

  .cs-wrapper {
    border-width: 1px;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .cs-p-title {
    margin-top: 80px;
    font-size: 34px !important;
  }

  .cs-info-item {
    padding: 4px;
  }

  .cs-p-header * {
    font-size: 18px !important;
  }

  .cs-p-data {
    gap: 16px !important;
    margin-top: 80px !important;
  }

  .cs-container {
    margin: 0 !important;
  }

  .cs-patient {
    margin-top: 60px !important;
  }

  .cs-patient-code {
    gap: 16px !important;
  }

  .content-none {
    border-bottom: 1px dotted;
  }

  .cs-font-small * {
    font-size: 13px !important;
  }

  // .cs-p-data * {
  //   font-size: 18px !important;
  // }
  .top-14 {
    top: 3.5rem;
  }
}
</style>
