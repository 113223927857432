<template>
  <div v-loading="isLoading" class="p-4 txt-grey-900 fs-14 fw-500">
    <div class="mb-3">
      <div class="flex items-center">
        <div class="flex gap-2 items-center justify-between w-full">
          <span class="title text-2xl text-black mr-2">{{
            $t("Bệnh Án")
          }}</span>
          <!-- <el-button @click="handleSwitchEmrMobile" size="mini" type="primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 32 32"
            >
              <circle cx="23" cy="24" r="2" fill="currentColor" />
              <path
                fill="currentColor"
                d="M30.777 23.479C29.354 20.149 26.3 18 23 18s-6.354 2.15-7.777 5.479L15 24l.223.522C16.646 27.85 19.7 30 23 30s6.354-2.15 7.777-5.478L31 24zM23 28c-2.206 0-4-1.794-4-4s1.794-4 4-4s4 1.794 4 4s-1.794 4-4 4"
              />
              <path
                fill="currentColor"
                d="M14 28H9V8h14v7h2V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v24c0 1.103.897 2 2 2h5zM9 4h14v2H9z"
              />
            </svg>
            {{ $t("Mobile view") }}
          </el-button> -->
        </div>
        <!-- <template v-if="isHasEHCSync">
          <el-tooltip
            class="item"
            effect="dark"
            content="Đồng bộ dữ liệu từ EHC"
            placement="top"
          >
            <el-button
              size="small"
              :class="{
                disabled: isSyncing,
              }"
              :disabled="isSyncing"
              @click="handleSyncEHC()"
            >
              <span v-if="!isSyncing">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-refresh-cw"
                >
                  <polyline points="23 4 23 10 17 10"></polyline>
                  <polyline points="1 20 1 14 7 14"></polyline>
                  <path
                    d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"
                  ></path>
                </svg>
              </span>
              <i v-else class="el-icon-loading text-xl" />
            </el-button>
          </el-tooltip>
        </template> -->
      </div>
    </div>
    <div class="flex text-sm font-semibold mb-6">
      <div
        class="cursor-pointer p-2.5 mr-3 txt-grey-800"
        :class="{ 'border-bottom-2': checkActive([1]) }"
        @click="setActive(1)"
      >
        Danh sách hồ sơ
      </div>
      <div
        class="cursor-pointer p-2.5 mr-3 txt-grey-800"
        :class="{ 'border-bottom-2': checkActive([3]) }"
        @click="setActive(3)"
      >
        Danh sách chờ
      </div>
      <div
        class="cursor-pointer p-2.5 mr-3 txt-grey-800"
        :class="{ 'border-bottom-2': checkActive([2]) }"
        @click="setActive(2)"
      >
        Thống kê
      </div>
    </div>
    <div class="bg-white rounded p-6 h-auto overflow-auto mt-2">
      <div
        class="d-flex mb-3"
        :class="checkActive([1, 3]) ? 'justify-content-between' : 'justify-end'"
      >
        <div v-if="checkActive([1, 3])" class="flex space-x-5">
          <div
            class="flex items-center border rounded-md border-grey-300 p-2 mr-2 w-60"
          >
            <IconSearch class="cursor-pointer" />
            <input
              v-model="valueSearch"
              @keypress.enter="
                () => (checkActive([1]) ? getBADT() : handleGetBADTWaiting())
              "
              class="border-0 txt-grey-500 ml-2 focus:outline-none"
              placeholder="Tên, SĐT, CCCD, BHYT"
            />
          </div>
          <div v-if="checkActive([1])" class="w-60">
            <el-select v-model="valueSelect" class="w-full" @change="getOption">
              <el-option
                v-for="item in listOptions"
                :key="item.id + 'md'"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="flex">
          <ButtonHoDo
            @click="showModalAddBenhAn(true)"
            title="Thêm Bệnh Án"
            class-name="bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
          />
        </div>
      </div>
      <div v-if="checkActive([1, 3])">
        <el-table
          :data="checkActive([1]) ? listBADT : listBADTWaiting"
          style="width: 100%"
          :row-class-name="tableRowClassName"
          header-cell-class-name="text-black text-base font-semibold"
          @row-click="goToDetail"
        >
          <el-table-column prop="person" label="Bệnh nhân">
            <template slot-scope="scope">
              <div class="font-medium">{{ formatPersonName(scope.row) }}</div>
              <div class="font-medium">{{ formatPersonPhone(scope.row) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ref_id" label="Mã điều trị"></el-table-column>
          <el-table-column
            prop="medical_record_name"
            label="Tên Bệnh án"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="Trạng thái"
            :formatter="getStatus"
          ></el-table-column>
          <el-table-column
            prop="treat_doctor"
            label="Bác sĩ điều trị"
            :formatter="getTreatDoctor"
          ></el-table-column>
          <!-- <el-table-column
            prop="updated_at"
            label="Ngày cập nhật"
            :formatter="getDateUpdate"
          >
          </el-table-column>-->
          <el-table-column
            prop="created_at"
            label="Ngày tạo"
            :formatter="getDateCreate"
          ></el-table-column>
          <el-table-column v-if="checkActive([1, 3])" label="Hành động">
            <template slot-scope="scope">
              <button
                v-if="checkActive([3])"
                class="flex items-center justify-center py-1.5 px-2 border-0 rounded-md focus:outline-none"
                style="color: #20419b; background-color: #e7eaf5"
                @click="(event) => handleShowAssignModal(event, scope.row)"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 3.04183C7.29276 3.04183 6.61448 3.32278 6.11438 3.82288C5.61428 4.32297 5.33333 5.00125 5.33333 5.7085C5.33333 6.41574 5.61428 7.09402 6.11438 7.59411C6.61448 8.09421 7.29276 8.37516 8 8.37516C8.70724 8.37516 9.38552 8.09421 9.88562 7.59411C10.3857 7.09402 10.6667 6.41574 10.6667 5.7085C10.6667 5.00125 10.3857 4.32297 9.88562 3.82288C9.38552 3.32278 8.70724 3.04183 8 3.04183ZM4 5.7085C4 4.64763 4.42143 3.63021 5.17157 2.88007C5.92172 2.12992 6.93913 1.7085 8 1.7085C9.06087 1.7085 10.0783 2.12992 10.8284 2.88007C11.5786 3.63021 12 4.64763 12 5.7085C12 6.76936 11.5786 7.78678 10.8284 8.53692C10.0783 9.28707 9.06087 9.7085 8 9.7085C6.93913 9.7085 5.92172 9.28707 5.17157 8.53692C4.42143 7.78678 4 6.76936 4 5.7085ZM5.33333 12.3752C4.8029 12.3752 4.29419 12.5859 3.91912 12.9609C3.54405 13.336 3.33333 13.8447 3.33333 14.3752C3.33333 14.552 3.2631 14.7215 3.13807 14.8466C3.01305 14.9716 2.84348 15.0418 2.66667 15.0418C2.48986 15.0418 2.32029 14.9716 2.19526 14.8466C2.07024 14.7215 2 14.552 2 14.3752C2 13.4911 2.35119 12.6433 2.97631 12.0181C3.60143 11.393 4.44928 11.0418 5.33333 11.0418H10.6667C11.5507 11.0418 12.3986 11.393 13.0237 12.0181C13.6488 12.6433 14 13.4911 14 14.3752C14 14.552 13.9298 14.7215 13.8047 14.8466C13.6797 14.9716 13.5101 15.0418 13.3333 15.0418C13.1565 15.0418 12.987 14.9716 12.8619 14.8466C12.7369 14.7215 12.6667 14.552 12.6667 14.3752C12.6667 13.8447 12.456 13.336 12.0809 12.9609C11.7058 12.5859 11.1971 12.3752 10.6667 12.3752H5.33333Z"
                    fill="currentColor"
                  />
                </svg>

                <div class="mt-0.25 ml-1.5 text-sm font-medium">Phân công</div>
              </button>
              <button
                v-if="
                  checkActive([1]) &&
                  scope.row.is_new === PATIENT_SYNC_HIS.isNew
                "
                class="flex flex-col items-center justify-center py-1.5 px-2 border-0 rounded-md focus:outline-none"
                @click.stop="handleSyncEmr(scope.row)"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z"
                    fill="black"
                  />
                </svg>

                <div class="mt-0.25 ml-1.5 text-sm font-medium">
                  Cập nhật từ HIS
                </div>
              </button>
            </template>
          </el-table-column>
        </el-table>
        <AppPagination
          :paginationProps="{
            currentPage: current_page,
            totalItems,
            pageSize: limit,
          }"
          @onChangePagination="(data) => handleChangePagination(data)"
        />
        <!-- <Pagination v-show="requests && requests.count" :items="requests" :current_page="current_page"
        @onRefresh="getBADT"></Pagination>-->
      </div>

      <div
        v-if="checkActive([2]) && statistics"
        class="fs-16 fw-600 flex justify-around items-center mt-5"
      >
        <div
          class="text-center cursor-pointer"
          v-for="(item, index) in statusBADT"
          :key="index + '_statistics'"
          @click="goToScreen(item.status)"
        >
          <div class="fs-32">{{ getCount(item) }}</div>
          <div class="text-blue-700">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <!-- <ModalAddBenhAn
    ref="ModalAddBenhAn"
      @refresh="getBADT"
      :badt="selectedBadt"
      :showModalUpdateBenhAn="showModalUpdateBenhAn"
    />-->
    <ModalAssignBenhAn
      ref="ModalAssignBenhAn"
      @onUpdateSuccess="handleGetBADTWaiting"
      :badtDetail="selectedBadtWaiting"
    />
    <ModalAddEMR ref="ModalAddEMR" @refresh="getBADT" />
  </div>
</template>

<script>
import { ButtonHoDo } from '@/components/Button'
import { AppPagination } from '@/components/Common'
import { IconSearch } from '@/components/Icon'
import statusBADT from '@/constants/statusBADT'
import appUtils from '../../utils/appUtils'
import { PATIENT_SYNC_HIS } from '../../utils/constants'
import ModalAddBenhAn from './ModalAddBenhAn.vue'
import ModalAddEMR from './ModalAddEMR.vue'
import ModalAssignBenhAn from './ModalAssignBenhAn.vue'
import WrapperBenhAn from './WrapperBenhAn.vue'

export default {
  components: {
    ButtonHoDo,
    IconSearch,
    ModalAddBenhAn,
    AppPagination,
    ModalAssignBenhAn,
    WrapperBenhAn,
    ModalAddEMR
  },
  name: 'ListBenhAn',
  data () {
    return {
      valueSearch: '',
      active: 1,
      listBADT: null,
      listBADTWaiting: null,
      current_page: 1,
      requests: null,
      selectedBadt: null,
      valueSelect: 'Bệnh Án toàn bộ Workspace',
      statusBADT,
      listOptions: [
        {
          id: 1,
          name: 'Bệnh Án toàn bộ Workspace'
        },
        {
          id: 2,
          name: 'Bệnh Án của tôi'
        }
      ],
      statistics: null,
      selectedBadtWaiting: null,
      limit: 15,
      totalItems: 0,
      wrapperId: null,
      PATIENT_SYNC_HIS,
      isLoading: false,
      isSyncing: false
    }
  },
  mounted () {
    this.handleSelected()
  },
  computed: {
    isHasEHCSync () {
      const settings = appUtils.isJSON(this.$globalClinic?.settings)
        ? JSON.parse(this.$globalClinic?.settings)
        : {}
      return !!settings?.ehc_config
    }
  },
  watch: {
    '$route.query.tab' () {
      this.handleSelected()
    }
  },
  methods: {
    handleSelected () {
      if (this.$route.query.tab) {
        if (this.$route.query.tab === 'general') {
          this.active = 2
          this.getBADT()
          this.getEMRStatistics()
        } else if (this.$route.query.tab === 'waiting') {
          this.active = 3
          this.handleGetBADTWaiting()
        } else {
          this.active = 1
          this.getBADT()
        }
      } else {
        this.active = 1
        // this.getBADT()
        this.$router.replace({ query: { tab: 'list' } })
      }

      // Page
      if (this.$route.query.page) {
        this.current_page = Number(this.$route.query.page)
      }
    },
    checkActive (index) {
      return index.includes(this.active)
    },
    setActive (index) {
      if (index === this.active) return
      if (index === 1) {
        this.$router.replace({ query: { tab: 'list' } })
      } else if (index === 3) {
        this.$router.replace({ query: { tab: 'waiting' } })
      } else {
        this.$router.replace({ query: { tab: 'general' } })
      }
      this.limit = 15
      this.current_page = 1
      this.totalItems = 0
    },
    goToDetail (item) {
      if (!item.id) {
        alert('BADT không tồn tại!')
        return
      }
      // this.wrapperId = item.id
      // this.showWrapperBenhAn(true)
      this.$router
        .push({ name: 'EMRWrapper', params: { id: item.id } })
        .catch((e) => {})
    },
    getStatus (row) {
      return row.status && row.status < 5
        ? this.statusBADT[row.status].name
        : this.statusBADT[0].name
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    getDateUpdate (row) {
      return (
        row.updated_at &&
        window.moment(row.updated_at).format('HH:mm DD/MM/YYYY')
      )
    },
    getDateCreate (row) {
      return (
        row.created_at &&
        window.moment(row.created_at).format('HH:mm DD/MM/YYYY')
      )
    },
    showModalAddBenhAn (show) {
      this.$refs.ModalAddEMR.handleShowModal(show)
      // window.$('#modal__add_benhan').modal(show ? 'show' : 'hide')
    },
    showModalUpdateBenhAn (item, show) {
      this.selectedBadt = item
      window.$('#modal__update_benhan').modal(show ? 'show' : 'hide')
    },
    showWrapperBenhAn (show) {
      window.$('#WrapperBenhAn').modal(show ? 'show' : 'hide')
    },
    async getEMRStatistics () {
      try {
        const params = {
          clinic_id: this.$globalClinic?.id || undefined
        }
        let resp = await this.$rf
          .getRequest('DoctorRequest')
          .getEMRStatistics(params)
        this.statistics = resp?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async getBADT (data) {
      if (!this.$globalClinic && !this.$globalClinic.id) return
      let self = this
      self.current_page = parseInt(self.$route.query.page) || 1
      const params = {
        clinic_id: this.$globalClinic.id,
        page: data?.currentPage || this.current_page,
        sort: 'created_at',
        sort_by: 'desc',
        limit: data?.pageSize || this.limit
      }
      if (this.valueSearch) {
        params.search = this.valueSearch
      }
      if (this.valueSelect === 2) {
        params.only_treat_doctor = true
      }
      if (this.$route.query.status) {
        params.status = Number(this.$route.query.status)
      }
      const r = await this.$rf
        .getRequest('DoctorRequest')
        .getBADT(params)
        .then((r) => {
          self.listBADT = r.data?.data
          self.requests = r.data
          self.totalItems = r.data?.total || 0
          self.current_page = r.data?.current_page || 1
          self.limit = Number(r.data?.per_page) || this.limit

          return r
        })
        .catch((e) => {
          console.log(e)
          self.$toast.open({
            message: 'BADT không tồn tại',
            type: 'error'
          })
          return false
        })
      return r
    },
    formatPersonName (row) {
      return row.person && row.person.name
    },
    getTreatDoctor (row) {
      if (row.treat_doctor && row.treat_doctor.name) {
        return row.treat_doctor && row.treat_doctor.name
      } else {
        return row.treat_doctor_name
      }
    },
    getGender (person) {
      return appUtils.getGender(person.gender)
    },
    formatPersonPhone (row) {
      if (!row.person) return ''
      let result = this.getGender(row.person)
      if (row.person.birthday) {
        result =
          result +
          ', ' +
          window.moment(row.person.birthday).format('DD-MM-YYYY')
      }
      if (row.person.address) result = result + ', ' + row.person.address
      return result
    },
    getOption () {
      this.valueSearch = ''
      if (this.$route.query.status) {
        let query = Object.assign({}, this.$route.query)
        delete query.status
        this.$router.replace({ query })
      }
      this.getBADT()
    },
    getCount (item) {
      const result = this.statistics.find((statistic) => {
        return statistic.status === item.status
      })
      return result ? result.count : item.count
    },
    goToScreen (status) {
      if (status === 0) {
        this.$router.replace({ query: { tab: 'waiting' } })
        return
      }

      this.$router.replace({ query: { tab: 'list', status } })
      this.getBADT()
    },
    handleShowAssignModal (event, data) {
      event.stopPropagation()
      this.selectedBadtWaiting = data
      this.$refs.ModalAssignBenhAn.openModal()
      // window.$('#modal_assign_benh_an').modal('show')
    },
    async handleGetBADTWaiting (data) {
      if (!this.$globalClinic && !this.$globalClinic.id) return
      let self = this

      self.current_page = parseInt(self.$route.query.page) || 1

      const params = {
        clinic_id: this.$globalClinic.id,
        page: data?.currentPage || this.current_page,
        sort: 'created_at',
        sort_by: 'desc',
        is_waiting: 1,
        limit: data?.pageSize || this.limit
      }
      if (this.valueSearch) {
        params.search = this.valueSearch
      }

      const r = await this.$rf
        .getRequest('DoctorRequest')
        .getBADTWaiting(params)
        .then((r) => {
          self.listBADTWaiting = r.data?.data
          self.requests = r.data
          self.totalItems = r.data?.total || 0
          self.current_page = r.data?.current_page || 1
          self.limit = Number(r.data?.per_page) || this.limit
          return r
        })
        .catch((e) => {
          console.log(e)
          self.$toast.open({
            message: 'BADT không tồn tại',
            type: 'error'
          })
          return false
        })
      return r
    },
    handleChangePagination (data) {
      if (data.currentPage) {
        this.$router.replace({
          query: {
            tab: this.$route.query.tab,
            page: data.currentPage
          }
        })
      }

      if (this.checkActive([1])) {
        this.getBADT(data)
      } else {
        this.handleGetBADTWaiting(data)
      }
    },
    tableRowClassName ({ row }) {
      let bgColor = ''

      if (row?.is_new === PATIENT_SYNC_HIS.isNew) {
        bgColor = 'cs-bg-highlight'
      }

      return `text-black text-sm cursor-pointer ${bgColor}`
    },
    async putSyncEMR (data) {
      try {
        this.isLoading = true

        await this.$rf.getRequest('DoctorRequest').putSyncEMR(data.ref_id)
        this.$toast.open({
          message: this.$t('Cập nhật thông tin bệnh án thành công'),
          type: 'success'
        })

        this.getBADT()
      } catch (error) {
        this.$toast.open({
          message: this.$t('Có lỗi trong quá trình đồng bộ'),
          type: 'warning'
        })

        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSyncEmr (data) {
      this.$confirm('Vui lòng xác nhận để đồng bộ thông tin bệnh án từ HIS', {
        confirmButtonText: 'Xác nhận'
      })
        .then((_) => {
          this.putSyncEMR(data)
        })
        .catch((_) => {})
    },
    async handleSyncEHC () {
      const self = this
      try {
        self.isSyncing = true
        const params = {
          org_id: self.$globalClinic?.org_id,
          ws_id: self.$globalClinic?.id
        }
        await self.$rf
          .getRequest('EHCSyncRequest')
          .ehcSyncEmr(params)
          .then((res) => {
            if (!res?.data) return
            if (res?.data?.code === 401) {
              self.$message({
                type: 'error',
                message: self.$t(`Bạn không thể đồng bộ dữ liệu từ EHC`)
              })
              console.log(res)
              return
            }
            console.log(res?.data)
            self.handleSelected()
            self.$message({
              type: 'success',
              message: self.$t(`Đồng bộ dữ liệu thành công`)
            })
            self.isSyncing = false
          })
      } catch (error) {
        console.log(error)
      }
    },
    handleSwitchEmrMobile () {
      const token = localStorage.getItem('jwt_token')
      const org_id = this.$globalClinic?.org_id
      const ws_id = this.$globalClinic?.id

      if (!token || (!ws_id && !org_id)) return ''

      let url
      if (process.env.NODE_ENV === 'development') {
        url = `https://beta.v2.hodo.app/#/emr-list?org_id=${org_id}&ws_id=${ws_id}&token=${token}`

        // url = `http://localhost:9051/#/emr-list?org_id=${org_id}&ws_id=${ws_id}&token=${token}`
      } else {
        url = `https://beta.v2.hodo.app/#/emr-list?org_id=${org_id}&ws_id=${ws_id}&token=${token}`
      }
      window.open(url, '_blank')
    }
  }
}
</script>

<style  lang="scss" scoped>
::placeholder {
  color: #98a2b3;
}

.border-bottom-2 {
  border-bottom: 2px solid #20419b !important;
}

.custom:hover {
  background-color: #e4e7ec;
}

.txt-red {
  color: #ff0e0e;
}

.bg-demo {
  background-color: #f6e6ad;
}

.border-custom {
  border: 1px solid #e4e7ec;
}

::v-deep {
  .cs-bg-highlight * {
    background-color: #fef0c7 !important;

    &:hover * {
      background-color: #fef0c7 !important;
    }
  }
}
</style>
