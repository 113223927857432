<template>
  <div>
    <el-dialog
      class="cs-dialog"
      fullscreen
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
    >
      <h2
        class="text-2xl md:text-3xl uppercase font-bold text-center mb-8"
        style="color: #20409b"
      >
        {{ $t("Tạo mới Bệnh án điện tử") }}
      </h2>
      <div
        class="flex items-center w-7/12 mx-auto my-16"
        style="max-width: 700px"
        :class="{
          'cs-bg-done': activeStep === 2,
        }"
      >
        <div
          @click="handleChangeStep(1)"
          class="w-14 h-14 p-2 border-2 border-solid rounded-full border-gray-400 relative flex items-center justify-center text-gray-400 cursor-pointer"
          :class="{
            'cs-active': activeStep === 1,
            'cs-done': activeStep === 2,
          }"
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 16.5C11.7879 16.5 12.5682 16.3448 13.2961 16.0433C14.0241 15.7418 14.6855 15.2998 15.2427 14.7427C15.7998 14.1855 16.2418 13.5241 16.5433 12.7961C16.8448 12.0682 17 11.2879 17 10.5C17 9.71208 16.8448 8.93187 16.5433 8.20391C16.2418 7.47596 15.7998 6.81453 15.2427 6.25737C14.6855 5.70022 14.0241 5.25827 13.2961 4.95674C12.5682 4.65521 11.7879 4.50002 11 4.50002C9.40872 4.50002 7.88259 5.13216 6.75737 6.25737C5.63216 7.38259 5.00002 8.90872 5.00002 10.5C5.00002 12.0913 5.63216 13.6174 6.75737 14.7427C7.88259 15.8679 9.40872 16.5 11 16.5ZM17.32 15.406L20.9 18.986C20.9955 19.0783 21.0716 19.1887 21.1239 19.3108C21.1762 19.4328 21.2037 19.564 21.2048 19.6968C21.2058 19.8296 21.1804 19.9613 21.13 20.0841C21.0797 20.207 21.0053 20.3186 20.9114 20.4124C20.8174 20.5062 20.7057 20.5804 20.5828 20.6306C20.4599 20.6808 20.3282 20.706 20.1954 20.7048C20.0626 20.7035 19.9314 20.6758 19.8095 20.6233C19.6875 20.5708 19.5772 20.4946 19.485 20.399L15.905 16.819C14.2975 18.0669 12.2748 18.6552 10.2488 18.4642C8.22273 18.2732 6.34562 17.3173 4.99957 15.7911C3.65351 14.2648 2.9397 12.283 3.00344 10.2489C3.06718 8.21492 3.90368 6.28164 5.34266 4.84266C6.78164 3.40368 8.71492 2.56718 10.7489 2.50344C12.783 2.4397 14.7648 3.15351 16.2911 4.49957C17.8173 5.84562 18.7732 7.72273 18.9642 9.74877C19.1552 11.7748 18.5669 13.7975 17.319 15.405L17.32 15.406Z"
              fill="currentColor"
            />
          </svg>
          <span
            class="absolute -bottom-10 -left-10 w-40 fs-16"
            :class="{
              'text-blue-900': activeStep === 1,
            }"
            >{{ $t("Tìm kiếm Bệnh nhân") }}</span
          >
        </div>
        <el-divider class="flex-1" />
        <div
          class="w-14 h-14 p-2 border-2 border-solid rounded-full border-gray-400 relative flex items-center justify-center text-gray-400"
          :class="{
            'cs-active': activeStep === 2,
          }"
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 11H11.5V14H8.5V16H11.5V19H13.5V16H16.5V14H13.5V11ZM14.5 2H6.5C5.4 2 4.5 2.9 4.5 4V20C4.5 21.1 5.39 22 6.49 22H18.5C19.6 22 20.5 21.1 20.5 20V8L14.5 2ZM18.5 20H6.5V4H13.5V9H18.5V20Z"
              fill="currentColor"
            />
          </svg>

          <span
            class="absolute -bottom-10 -left-10 w-40 fs-16"
            :class="{
              'text-blue-900': activeStep === 2,
            }"
            >{{ $t("Tạo Bệnh án điện tử") }}</span
          >
        </div>
      </div>
      <div
        v-loading="isLoading"
        class="container text-black hodo-el bg-white p-4"
      >
        <div v-if="activeStep === 1">
          <div
            class="flex flex-col-reverse sm:flex-row justify-between mb-2 items-end w-full gap-2"
          >
            <h3 class="text-lg md:text-2xl font-bold mb-0">
              {{ $t("Tìm kiếm Bệnh nhân") }}
            </h3>
            <div class="fs-16">
              {{ $t("Hoặc tạo mới Bệnh nhân") }}
              <strong
                @click="showModalAddPerson(true)"
                class="text-blue-900 cursor-pointer"
                >{{ $t("tại đây") }}</strong
              >
            </div>
          </div>
          <form
            @submit.prevent="handleSearchPatient"
            class="flex flex-col md:flex-row gap-2 border px-4 py-4 rounded justify-between items-center md:items-end"
          >
            <div class="flex flex-col gap-1 w-full md:gap-3 md:flex-1">
              <label class="font-bold fs-18">{{ $t("Tên Bệnh nhân") }}</label>
              <el-input
                :disabled="isLoading"
                v-model="search.name"
                class="fw-500"
                :placeholder="$t('Nhập tên bệnh nhân')"
              />
            </div>

            <div class="flex flex-col gap-1 w-full md:gap-3 md:flex-1">
              <label class="font-bold fs-18">{{
                $t("Mã Bệnh nhân (PID)")
              }}</label>
              <el-input
                :disabled="isLoading"
                v-model="search.pid"
                class="fw-500"
                :placeholder="$t('Nhập mã bệnh nhân')"
              />
            </div>

            <div class="flex flex-col gap-1 w-full md:gap-3 md:flex-1">
              <label class="font-bold fs-18">{{
                $t("Căn cước công dân")
              }}</label>
              <el-input
                :disabled="isLoading"
                v-model="search.cmnd"
                class="fw-500"
                :placeholder="$t('Nhập căn cước công dân')"
              />
            </div>
            <div class="flex flex-col gap-1 w-full md:gap-3 md:flex-1">
              <label class="font-bold fs-18">{{ $t("Mã điều trị") }}</label>
              <el-input
                :disabled="isLoading"
                v-model="search.so_ho_so"
                class="fw-500"
                :placeholder="$t('Nhập mã điều trị')"
              />
            </div>

            <button
              :disabled="isLoading"
              @click="handleSearchPatient"
              type="submit"
              class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 text-white rounded-md h-11 px-3 py-2 ml-auto w-38"
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.7099 20.2899L17.9999 16.6099C19.44 14.8143 20.1374 12.5352 19.9487 10.2412C19.76 7.94721 18.6996 5.81269 16.9854 4.27655C15.2713 2.74041 13.0337 1.91941 10.7328 1.98237C8.43194 2.04534 6.24263 2.98747 4.61505 4.61505C2.98747 6.24263 2.04534 8.43194 1.98237 10.7328C1.91941 13.0337 2.74041 15.2713 4.27655 16.9854C5.81269 18.6996 7.94721 19.76 10.2412 19.9487C12.5352 20.1374 14.8143 19.44 16.6099 17.9999L20.2899 21.6799C20.3829 21.7736 20.4935 21.848 20.6153 21.8988C20.7372 21.9496 20.8679 21.9757 20.9999 21.9757C21.1319 21.9757 21.2626 21.9496 21.3845 21.8988C21.5063 21.848 21.6169 21.7736 21.7099 21.6799C21.8901 21.4934 21.9909 21.2442 21.9909 20.9849C21.9909 20.7256 21.8901 20.4764 21.7099 20.2899ZM10.9999 17.9999C9.61544 17.9999 8.26206 17.5894 7.11091 16.8202C5.95977 16.051 5.06256 14.9578 4.53275 13.6787C4.00293 12.3996 3.86431 10.9921 4.13441 9.63427C4.4045 8.27641 5.07119 7.02912 6.05016 6.05016C7.02912 5.07119 8.27641 4.4045 9.63427 4.13441C10.9921 3.86431 12.3996 4.00293 13.6787 4.53275C14.9578 5.06256 16.051 5.95977 16.8202 7.11091C17.5894 8.26206 17.9999 9.61544 17.9999 10.9999C17.9999 12.8564 17.2624 14.6369 15.9497 15.9497C14.6369 17.2624 12.8564 17.9999 10.9999 17.9999Z"
                  fill="white"
                />
              </svg>
              <div class="ml-2">{{ $t("Tìm kiếm") }}</div>
            </button>
          </form>

          <div class="mt-14">
            <h3 class="text-lg md:text-2xl font-bold mb-0">
              {{ $t("Kết quả tìm kiếm") }}
            </h3>
            <small class="fs-15 italic">{{
              $t("( Vui lòng chọn Bệnh nhân để tạo bệnh án )")
            }}</small>

            <el-table
              :data="patientList"
              style="width: 100%"
              row-class-name="text-black text-sm cursor-pointer"
              header-cell-class-name="text-black text-sm font-semibold"
              @row-click="handleSelectedPatient"
              :empty-text="$t('Không có dữ liệu Bệnh nhân')"
            >
              <el-table-column prop="name" label="Họ Tên">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.name"
                    :class="{
                      'bg-yellow-100 p-1': !!search.name,
                    }"
                    >{{ scope.row.name }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="pid" label="Mã Bệnh nhân (PID)">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.pid"
                    :class="{
                      'bg-yellow-100 p-1': !!search.pid,
                    }"
                    >{{ scope.row.pid }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="cmnd" label="CCCD">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.cmnd"
                    :class="{
                      'bg-yellow-100 p-1': !!search.cmnd,
                    }"
                    >{{ scope.row.cmnd }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                :formatter="formatDMY"
                label="Ngày sinh"
              ></el-table-column>
              <el-table-column prop="phone" label="SĐT"></el-table-column>
              <el-table-column
                prop="address"
                :formatter="(data) => getAddressPatient(data)"
                label="Địa chỉ"
              ></el-table-column>
              <!-- <el-table-column label="Trạng thái kết nối">
                <template slot-scope="scope">
                  <span
                    class="label-approve"
                    :class="statusConnectOrgVariant(scope.row).color"
                  >{{ statusConnectOrgVariant(scope.row).text }}</span>
                </template>
              </el-table-column>-->
            </el-table>
          </div>
        </div>

        <div v-if="activeStep === 2" class>
          <h3 class="text-3xl font-bold">
            {{ $t("Tạo mới Bệnh án điện tử") }}
          </h3>
          <div v-if="selectedPatient" class="mt-8 flex flex-col gap-4 md:gap-8">
            <div class="row">
              <div
                :class="
                  !medicalRecordData || !medicalRecordData.ref_id
                    ? 'col-md-8'
                    : 'col-12'
                "
              >
                <label class="fs-16 fw-500 required">{{
                  $t("Tên Hồ sơ Bệnh Án")
                }}</label>
                <el-input
                  v-model="form.name"
                  type="text"
                  class="fs-16 fw-500 h-10"
                  :placeholder="$t('Nhập Tên Hồ sơ Bệnh Án')"
                />
              </div>
              <!-- <div v-if="!medicalRecordData" class="flex-1">
                <label class="fs-16 fw-500 required">{{ $t('Mã điều trị') }}</label>
                <el-input
                  v-model="form.ref_id"
                  type="text"
                  class="fs-16 fw-500 h-10"
                  :placeholder="$t('Nhập mã điều trị')"
                />
              </div>-->
              <div
                v-if="!medicalRecordData || !medicalRecordData.ref_id"
                class="col-md-4"
              >
                <div class="fs-16 fw-500">{{ $t("Mã điều trị") }}</div>
                <el-input
                  v-model="form.ref_id"
                  type="text"
                  class="fs-16 fw-500 h-10"
                  :placeholder="$t('Nhập mã điều trị')"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="fs-16 fw-500">
                  {{ $t("Bác sĩ Chính/Bác sĩ trưởng khoa") }}
                </div>
                <el-select
                  filterable
                  allow-create
                  v-model="form.main_doctor"
                  class="w-full"
                  :placeholder="$t('Chọn bác sĩ chính')"
                >
                  <el-option
                    v-for="item in mainDoctors"
                    :key="item.id + 'md'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="col-md-4">
                <div class="fs-16 fw-500">{{ $t("Bác sĩ Điều trị") }}</div>
                <el-select
                  filterable
                  allow-create
                  v-model="form.treat_doctor"
                  class="w-full"
                  :placeholder="$t('Chọn bác sĩ điều trị')"
                >
                  <el-option
                    v-for="item in treatDoctors"
                    :key="item.id + 'td'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="col-md-4">
                <div class="fs-16 fw-500">{{ $t("Người hỗ trợ") }}</div>
                <el-select
                  filterable
                  allow-create
                  v-model="form.support_doctor"
                  class="w-full"
                  :placeholder="$t('Chọn người hỗ trợ')"
                >
                  <el-option
                    v-for="item in support_doctors"
                    :key="item.id + 'md'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row gap-4 mt-10">
              <div class="flex items-center gap-2 col-md-4">
                <div class="fs-18 fw-400">{{ $t("Bệnh nhân:") }}</div>
                <span class="font-semibold fs-18">{{
                  selectedPatient.name
                }}</span>
              </div>

              <div class="flex items-center gap-2 col-md-4">
                <label class="fs-18 fw-400">{{
                  $t("Mã Bệnh nhân (PID):")
                }}</label>
                <span class="font-semibold fs-18">{{
                  form.person_ref_id
                }}</span>
              </div>
              <div class="flex items-center gap-2 col-md-4">
                <div class="fs-18 fw-400">{{ $t("Căn cước công dân:") }}</div>
                <span class="font-semibold fs-18">{{
                  selectedPatient.cmnd
                }}</span>
              </div>
            </div>
            <div class="flex flex-col md:flex-row gap-4">
              <div class="flex items-center gap-2 col-md-4">
                <div class="fs-18 fw-400">{{ $t("Năm sinh:") }}</div>
                <span class="font-semibold fs-18">{{
                  getYearBorn(selectedPatient)
                }}</span>
              </div>
              <div class="flex items-center gap-2 col-md-4">
                <div class="fs-18 fw-400">{{ $t("Giới tính:") }}</div>
                <span class="font-semibold fs-18">{{
                  getGender(selectedPatient)
                }}</span>
              </div>
              <div class="flex items-center gap-2 col-md-4">
                <div class="fs-18 fw-400">{{ $t("Nghề nghiệp:") }}</div>
                <span class="font-semibold fs-18">{{
                  selectedPatient.career
                }}</span>
              </div>
            </div>
            <div class>
              <div class="flex flex-col md:flex-row gap-2">
                <div class="fs-18 fw-400">{{ $t("Địa chỉ:") }}</div>
                <span class="font-semibold fs-18">{{
                  getAddress(selectedPatient)
                }}</span>
              </div>
            </div>
            <div
              v-if="medicalRecordData"
              class="flex flex-col md:flex-row gap-4"
            >
              <div class="flex items-center gap-2 col-md-6">
                <label class="fs-18 fw-400">{{ $t("Mã điều trị:") }}</label>
                <span class="font-semibold fs-18">{{
                  medicalRecordData.ref_id
                }}</span>
              </div>
              <div
                v-if="medicalRecordData.visit_reasons"
                class="flex items-center gap-2 col-md-6"
              >
                <div class="fs-18 fw-400">{{ $t("Lý do nhập viện:") }}</div>
                <span class="font-semibold fs-18">{{
                  medicalRecordData.visit_reasons
                }}</span>
              </div>
            </div>
            <div
              v-if="medicalRecordData"
              class="flex flex-col md:flex-row gap-4"
            >
              <div
                v-if="medicalRecordData.reception_at"
                class="flex items-center gap-2 col-md-6"
              >
                <div class="fs-18 fw-400">{{ $t("Ngày nhập viện:") }}</div>
                <span class="font-semibold fs-18">{{
                  getDate(medicalRecordData.reception_at)
                }}</span>
              </div>
              <div
                v-if="medicalRecordData.discharge_at"
                class="flex items-center gap-2 col-md-6"
              >
                <div class="fs-18 fw-400">{{ $t("Ngày xuất viện:") }}</div>
                <span class="font-semibold fs-18">{{
                  getDate(medicalRecordData.discharge_at)
                }}</span>
              </div>
            </div>

            <button
              :disabled="isLoading"
              @click="handleSubmitEMR"
              type="submit"
              class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 text-white rounded-md h-10 px-3 py-2 ml-auto w-42 mt-2"
            >
              <div class="ml-2">{{ $t("Tạo Bệnh án điện tử") }}</div>
            </button>

            <div v-if="emrList.length" class="mt-14">
              <h3 class="text-lg md:text-2xl font-bold mb-0">
                {{ $t(`Bệnh án điện tử HODO `) }}
                <span>{{ `(${emrList.length})` }}</span>
              </h3>
              <el-table
                :data="emrList"
                style="width: 100%"
                row-class-name="text-black text-sm cursor-pointer"
                header-cell-class-name="text-black text-sm font-semibold"
                @row-click="handleSelectedEMR"
                :empty-text="$t('Không có dữ liệu Bệnh án')"
              >
                <el-table-column
                  prop="ref_id"
                  label="Mã Bệnh án"
                ></el-table-column>
                <el-table-column
                  prop="medical_record_name"
                  label="Tên Bệnh án"
                ></el-table-column>
                <el-table-column
                  prop="status"
                  label="Trạng thái"
                  :formatter="getEmrStatus"
                ></el-table-column>
                <el-table-column
                  prop="treat_doctor"
                  label="Bác sĩ điều trị"
                  :formatter="getTreatDoctor"
                ></el-table-column>
                <el-table-column
                  prop="created_at"
                  label="Ngày vào viện"
                  :formatter="getReceptionAt"
                ></el-table-column>
              </el-table>
            </div>
          </div>
          <div v-else class="text-center text-xl text-gray-400 mt-10">
            {{ $t("Chưa có thông tin của Bệnh án điện tử") }}
          </div>
        </div>
      </div>
    </el-dialog>

    <ModalPatientInfo
      ref="ModalPatientInfo"
      @refresh="handleAddPatientSuccess"
    ></ModalPatientInfo>
  </div>
</template>

<script>
import { ButtonHoDo } from '@/components/Button'
import statusBADT from '@/constants/statusBADT'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import ModalPatientInfo from '../../components/Patient/ModalPatientInfo.vue'
import appUtils from '../../utils/appUtils'
import { PATIENT_SOURCE_TYPE, SYNC_HIS } from '../../utils/constants'

export default {
  name: 'ModalAddEMR',
  mixins: [mixinGetPersonInfo],
  components: {
    ButtonHoDo,
    ModalPatientInfo
  },
  data () {
    return {
      dialogVisible: false,
      search: {
        so_ho_so: '',
        pid: '',
        cmnd: '',
        name: ''
      },
      selectedPatient: {},
      isLoading: false,
      doctors: [],
      form: {
        name: '',
        main_doctor: null,
        treat_doctor: null,
        support_doctor: null,
        ref_id: '',
        person_ref_id: '',
        person_id: ''
      },
      activeStep: 1,
      medicalRecordData: null,
      patientList: [],
      emrList: [],
      statusBADT,
      backRouter: ''
    }
  },
  computed: {
    mainDoctors () {
      return this.doctors.filter(
        (doctor) =>
          [0, 2].includes(doctor.type) &&
          (!this.form.treat_doctor || this.form.treat_doctor !== doctor.id) &&
          (!this.form.support_doctor || this.form.support_doctor !== doctor.id)
      )
    },
    treatDoctors () {
      return this.doctors.filter(
        (doctor) =>
          [0, 2].includes(doctor.type) &&
          (!this.form.main_doctor || this.form.main_doctor !== doctor.id) &&
          (!this.form.support_doctor || this.form.support_doctor !== doctor.id)
      )
    },
    support_doctors () {
      return this.doctors.filter(
        (doctor) =>
          (!this.form.main_doctor || this.form.main_doctor !== doctor.id) &&
          (!this.form.treat_doctor || this.form.treat_doctor !== doctor.id)
      )
    }
  },
  watch: {
    $route: {
      deep: true,
      handler (data) {
        if (data?.query?.perId) {
          this.dialogVisible = true
          this.activeStep = 2
          this.handleGetPatientDetail(data?.query?.perId)
          this.getDoctors()
        }

        if (data?.query?.back) {
          this.backRouter = data?.query?.back
        } else {
          this.backRouter = ''
        }
      }
    }
  },
  created () {
    if (this.$route?.query?.perId) {
      this.dialogVisible = true
      this.activeStep = 2
      this.handleGetPatientDetail(this.$route?.query?.perId)
      this.getDoctors()
    }

    if (this.$route?.query?.back) {
      this.backRouter = this.$route?.query?.back
    } else {
      this.backRouter = ''
    }
  },
  methods: {
    handleShowModal (isShow) {
      this.dialogVisible = isShow
      if (!isShow) return

      this.getDoctors()
    },
    handleClose () {
      if (this.backRouter) {
        this.$router.replace({
          name: this.backRouter
        })
      } else {
        const newQuery = {
          ...this.$route.query
        }

        delete newQuery.perId
        delete newQuery.emr_code

        this.$router.replace({
          query: newQuery
        })
      }

      this.handleShowModal(false)
      this.form = {}
      this.selectedPatient = null
      this.search = {}
      this.activeStep = 1
      this.medicalRecordData = null
      this.patientList = []
    },
    async handleSearchPatient (e) {
      e.preventDefault()
      if (!this.$globalClinic?.id) return

      try {
        this.selectedPatient = null
        this.form = {
          name: '',
          main_doctor: null,
          treat_doctor: null,
          support_doctor: null,
          ref_id: '',
          person_ref_id: '',
          person_id: ''
        }
        this.isLoading = true

        const params = {
          ...this.search,
          ws_id: this.$globalClinic?.id
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getPatientSyncInfo(params)

        this.patientList = response?.data?.data_person?.data || []
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    showModalAddPerson () {
      this.$refs.ModalPatientInfo.openDialog(true)
    },
    handleAddPatientSuccess (patient) {
      if (this.search.so_ho_so && patient.is_sync === SYNC_HIS.notSync) {
        this.$router.replace({
          query: {
            tab: 'list',
            perId: patient?.id,
            emr_code: this.search.so_ho_so
          }
        })
      } else {
        this.$router.replace({
          query: {
            tab: 'list',
            perId: patient?.id
          }
        })
      }

      this.selectedPatient = patient || {}
      this.form = {
        person_ref_id: patient?.pid,
        person_id: patient?.id,
        name: '',
        main_doctor: null,
        treat_doctor: null,
        support_doctor: null
      }
    },
    async getDoctors () {
      try {
        const clinic_id = this.$clinicId
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
        this.doctors = res.data?.data
      } catch (error) {
        console.log(error)
      }
    },
    async handleSubmitEMR () {
      let self = this

      if (!self.$globalClinic && !self.$globalClinic.id) return
      if (!self.form.name || !self.form.name.length || !self.form.person_id) {
        alert(this.$t('Vui lòng điền đầy đủ thông tin'))
        return
      }
      if (isNaN(self.form.main_doctor)) {
        self.form.main_doctor_name = self.form.main_doctor
        self.form.main_doctor = null
      }
      if (isNaN(self.form.treat_doctor)) {
        self.form.treat_doctor_name = self.form.treat_doctor
        self.form.treat_doctor = null
      }

      if (isNaN(self.form.support_doctor)) {
        self.form.supporter_name = self.form.support_doctor
        self.form.support_doctor = null
      }

      const params = {
        clinic_id: self.$globalClinic.id,
        main_doctor: self.form.main_doctor,
        treat_doctor: self.form.treat_doctor,
        supporter_id: self.form.support_doctor,
        supporter_name: self.form.supporter_name,
        treat_doctor_name: self.form.treat_doctor_name,
        main_doctor_name: self.form.main_doctor_name,
        person_ref_id: self.form.person_ref_id,
        person_id: self.form.person_id,
        medical_record_name: self.form.name
      }

      if (params.treat_doctor || params.treat_doctor_name) {
        params.status = 1
      }

      this.isLoading = true

      if (this.medicalRecordData?.ref_id) {
        try {
          const response = await this.$rf
            .getRequest('DoctorRequest')
            .postCreateEMR({
              ...params,
              ref_id: String(self.medicalRecordData.ref_id),
              source_type: 2
            })

          self.$emit('refresh')

          self.handleSuccess(false)

          self.$toast.open({
            message: self.$t('Tạo mới Bệnh án điện tử thành công'),
            type: 'success'
          })
          return response
        } catch (error) {
          if (error.response.message.includes('Duplicate ref id')) {
            self.$toast.open({
              message: self.$t('Mã điều trị đã tồn tại, vui lòng nhập mã khác'),
              type: 'warning'
            })
            return false
          }
          self.$toast.open({
            message: self.$t('Có lỗi trong quá trình tạo'),
            type: 'warning'
          })
          return false
        } finally {
          this.isLoading = false
        }
      } else {
        await self.$rf
          .getRequest('DoctorRequest')
          .postCreateEMR({
            ...params,
            ref_id: this.form.ref_id
          })
          .then((r) => {
            self.$emit('refresh')

            self.handleSuccess(false)

            self.$toast.open({
              message: self.$t('Tạo mới Bệnh án điện tử thành công'),
              type: 'success'
            })
            return r
          })
          .catch((e) => {
            console.log(e.response)
            if (e.response?.data?.message.includes('Duplicate ref id')) {
              self.$toast.open({
                message: self.$t(
                  'Mã điều trị đã tồn tại, vui lòng nhập mã khác'
                ),
                type: 'warning'
              })
              return false
            }
            self.$toast.open({
              message: self.$t('Có lỗi trong quá trình tạo'),
              type: 'warning'
            })
            return false
          })
          .finally(() => {
            this.isLoading = false
          })
      }

      this.isLoading = false
    },
    handleShowConfirmPatient (patientInfo) {
      this.$refs.ModalPatientInfo.openDialog(
        true,
        'Thông tin Bệnh nhân',
        patientInfo,
        true
      )
    },
    handleGetPatientLabel (data) {
      const name = data?.name || ''

      if (!data.birthday) return name
      const date = window.moment(data.birthday).format('YYYY')
      const dateLabel = date ? `- ${date}` : ''
      return `${name} ${dateLabel}`
    },
    handleChangeStep (step) {
      if (step === 1) {
        const newQuery = {
          ...this.$route.query
        }

        delete newQuery.perId
        delete newQuery.emr_code

        this.$router.replace({
          query: newQuery
        })
      }
      this.form = {}
      this.selectedPatient = null
      this.search = {}
      this.activeStep = step
    },
    getYearBorn (data) {
      if (!data.birthday) return ''
      return window.moment(data.birthday).format('YYYY')
    },
    getGender (data) {
      return appUtils.getGender(data.gender)
    },
    getDate (date) {
      if (!date) return
      const array = window.moment(date).format('HH:mm DD-MM-YYYY')
      const hour = array.split(' ')[0].split(':')
      const date2 = array.split(' ')[1].split('-')
      return `${hour[0]} giờ ${hour[1]} phút, ngày ${date2[0]}/${date2[1]}/${date2[2]}`
    },
    statusConnectOrgVariant (person) {
      let label_color
      let lable_text
      switch (person.patient_connect_request?.status) {
        case 1:
          label_color = 'bc-green'
          lable_text = 'Y/c kết nối'
          break
        case 2:
          label_color = 'bc-green'
          lable_text = 'Đã kết nối'
          break
        case 3:
          label_color = 'bc-red'
          lable_text = 'Bị từ chối'
          break
        default:
          label_color = 'bc-yellow'
          lable_text = 'Chưa kết nối'
          break
      }
      return { color: label_color, text: lable_text }
    },
    handleSelectedPatient (patientInfo) {
      if (patientInfo?.pid && patientInfo?.is_sync === SYNC_HIS.notSync) {
        const rawData = {
          ...patientInfo,
          admission_hospital_date: patientInfo.admission_hospital_date
            ? window
              .moment(patientInfo.admission_hospital_date)
              .format('YYYY-MM-DD HH:mm:ss')
            : '',
          birthday: patientInfo.birthday
            ? window.moment(patientInfo.birthday).format('YYYY-MM-DD')
            : '',
          insurance_start_date: patientInfo.insurance_start_date
            ? window
              .moment(patientInfo.insurance_start_date)
              .format('YYYY-MM-DD')
            : '',
          insurance_expire: patientInfo.insurance_expire
            ? window
              .moment(patientInfo.insurance_expire, 'DD/MM/YYYY')
              .format('YYYY-MM-DD')
            : ''
        }

        this.handleShowConfirmPatient(rawData)
      } else {
        this.handleAddPatientSuccess(patientInfo)
      }
    },
    formatDMY (row) {
      return row.birthday && window.moment(row.birthday).format('DD-MM-YYYY')
    },
    getReceptionAt (row) {
      return row.medical_record_reception_at
        ? window
          .moment(row.medical_record_reception_at)
          .format('HH:mm DD/MM/YYYY')
        : row.medical_record_reception_at
          ? window
            .moment(row.medical_record_reception_at)
            .format('HH:mm DD/MM/YYYY')
          : ''
    },
    getEmrStatus (row) {
      return row.status && row.status < 5
        ? this.statusBADT[row.status].name
        : this.statusBADT?.[0].name
    },
    getTreatDoctor (row) {
      if (row.treat_doctor_infor?.User?.name) {
        return row.treat_doctor_infor?.User?.name
      } else {
        return row.treat_doctor_name
      }
    },
    async handleGetEMRByPID (pid) {
      const ws_id = this.$globalClinic?.id
      if (!pid || !ws_id) return

      try {
        this.isLoading = true
        const params = {
          ws_id,
          pid
        }

        if (this.$route.query.emr_code) {
          params.doc_no = this.$route.query.emr_code
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getEMRByPID(params)

        this.medicalRecordData = response?.data?.his_data
        this.form.name = this.medicalRecordData?.name || ''
        this.emrList = response?.data?.data_medical || []
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSelectedEMR (data) {
      if (!data?.id) return

      this.$router.push({
        name: 'ERMTreeView',
        params: {
          id: data.id
        },
        query: {
          tab: 'master'
        }
      })
    },
    async handleGetPatientDetail (id) {
      if (!id) return

      try {
        this.isLoading = true
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getPersonDetail(id)
        this.selectedPatient = response.data
        this.form = {
          person_ref_id: response.data?.pid,
          person_id: response.data?.id,
          name: '',
          main_doctor: null,
          treat_doctor: null,
          support_doctor: null
        }

        await this.handleGetEMRByPID(response.data?.pid)
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSuccess () {
      const newQuery = {
        ...this.$route.query
      }

      delete newQuery.perId
      delete newQuery.emr_code

      this.$router.replace({
        query: newQuery
      })

      this.handleShowModal(false)
      this.form = {}
      this.selectedPatient = null
      this.search = {}
      this.activeStep = 1
      this.medicalRecordData = null
      this.patientList = []
    },
    getAddressPatient (data = {}) {
      let source_type
      if (data.is_sync === SYNC_HIS.notSync) {
        source_type = PATIENT_SOURCE_TYPE.HIS
      } else {
        source_type = PATIENT_SOURCE_TYPE.HODO
      }

      const cloneData = { ...data, source_type }

      return this.getAddress(cloneData)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-divider {
    height: 2px;
  }
  .el-dialog {
    background-color: #fcfcfd;
  }
  .hodo-el {
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
      height: 44px !important;

      &:focus {
        border-color: #a9b6df !important;
      }
    }

    .el-input.is-disabled .el-input__inner {
      background-color: #f5f7fa !important;
      border-color: #e4e7ed !important;
      color: #656973 !important;
    }
  }

  .cs-step {
    .el-step__arrow {
      &::after,
      &::before {
        background: black;
      }
    }
  }

  .cs-bg-done {
    .el-divider {
      background-color: #32d583 !important;
    }
  }
}

.cs-active {
  border-color: #536cbc !important;
  background-color: #9ba8d7 !important;
  color: white;
}

.cs-done {
  border-color: #32d583 !important;
  background-color: #d1fadf !important;
  color: #32d583;
}
</style>
